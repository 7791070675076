import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const utilApi = createApi({
    reducerPath: 'getCurrenciesApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_AUDIENCE_URL }),
    endpoints: (builder) => ({
        getCurrencies: builder.mutation({
            query: ({ accessToken }) => {
                return {
                    url: 'getCurrencies',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        getRegistrationQuestions: builder.mutation({
            query: (accessToken) => {
                return {
                    url: 'registration/questions/read',
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        saveUserQnA: builder.mutation({
            query: ({ accessToken, _id, questionObjId, answerObjId }) => {
                var formData = {
                    "_id": _id,
                    "questionObjId": questionObjId,
                    "answerObjId": answerObjId,
                }

                return {
                    url: 'userQnA/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        deleteUserQnA: builder.mutation({
            query: ({ accessToken, _id, questionObjId, answerObjId }) => {
                var formData = {
                    "_id": _id,
                    "questionObjId": questionObjId,
                    "answerObjId": answerObjId,
                }
                return {
                    url: 'userQnA/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        getSoftwareCategories: builder.mutation({
            query: (accessToken) => {
                return {
                    url: 'registration/softwarecategory/list',
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        saveUserSoftware: builder.mutation({
            query: ({ accessToken, _id, sofwareCategoryObjId, softwareObjId }) => {
                var formData = {
                    "_id": _id,
                    "sofwareCategoryObjId": sofwareCategoryObjId,
                    "softwareObjId": softwareObjId,
                }

                return {
                    url: 'userSoftware/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        deleteUserSoftware: builder.mutation({
            query: ({ accessToken, _id }) => {
                var formData = {
                    "_id": _id
                }
                return {
                    url: 'userSoftware/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        saveSubService: builder.mutation({
            query: ({ accessToken, _id, subServiceName }) => {
                var formData = {
                    "_id": _id,
                    "subServiceName": subServiceName,
                }
                return {
                    url: 'subservices/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        deleteSubService: builder.mutation({
            query: ({ accessToken, _id, subServiceObjId }) => {
                var formData = {
                    "_id": _id,
                    "subServiceObjId": subServiceObjId,
                }
                return {
                    url: 'subservices/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        updateSubService: builder.mutation({
            query: ({ accessToken, _id, subServiceObjId, subServiceName }) => {
                var formData = {
                    "_id": _id,
                    "subServiceObjId": subServiceObjId,
                    "subServiceName": subServiceName
                }
                return {
                    url: 'subservices/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
    }),
});

export const {
    useGetCurrenciesMutation,
    useGetSoftwareCategoriesMutation,
    useSaveUserSoftwareMutation,
    useDeleteUserSoftwareMutation,
    useGetRegistrationQuestionsMutation,
    useSaveUserQnAMutation,
    useDeleteUserQnAMutation,
    useSaveSubServiceMutation,
    useDeleteSubServiceMutation,
    useUpdateSubServiceMutation
} = utilApi;