import React, { useEffect, useState, Fragment } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  superAdminIconArray,
  spIconArray
} from "../../constants/NavbarRoutesArray";
import Modal from "react-modal";
import hut from "../../Assets/images/hut.svg";
import bell from "../../Assets/images/bellIcon.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import circle from "../../Assets/images/circle.png";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import { useNavigate } from "react-router-dom";
import dummyImage from "../../Assets/images/dummyImage.png";
import ServiceCarouselTile from "../../Components/UserManagement-Comps/ServiceCarouselTile";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import ServiceProviderCard from "../../Components/OrderAdmin-comps/ServiceProviderCard";
import ticket from "../../Assets/icons/ticket.svg";
import replyIcon from "../../Assets/icons/replyIcon.svg";
import { ImAttachment } from "react-icons/im";
import { useLocation } from 'react-router-dom';
import {
  useGetOrderByIdMutation,
  useGetServiceProviderByServiceMutation,
  useGetServiceProviderOrdersMutation,
  useReadCommOrdersMutation,
  useSaveCommOrdersMutation,
  useSaveServiceProviderOrdersMutation,
  useUpdateCommOrdersMutation
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import greenTick from "../../Assets/images/greenTick.svg";
import { LoginButton } from "../Login/Login";
import { AiOutlineArrowUp, AiOutlineClose } from "react-icons/ai";
import TextInputField from "../../Components/InputField-Comps/TextInputField";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import ConcernCard from "../../Components/Order-Comps/ConcernCard";
import AttachUpload from "../../Components/Order-Comps/AttachUpload";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";

const displayOptions = require("../../Utils/orders-form1-options.json");

export const OrderDetailSummary = (data) => {
  const { getAccessTokenSilently } = useAuth0();
  const [getOrderById] = useGetOrderByIdMutation();
  const [orderDetails, setOrderDetails] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const getOrderData = async (accessToken, orderId) => {
    const resp = await getOrderById({ accessToken, orderId }).unwrap();
    if (resp) {
      // debugger
      setOrderDetails(resp.data[0]);
      // debugger
    }
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getOrderData(accessToken, data.data.orderObjId);
    }
  };

  useEffect(() => {
    console.log("useEffect services working --- ");
    readOrderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // "Error in performing this action - reducer failed"
    <>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Patient Initials</p>
          <p className="text-[#555] font-medium mt-2">{orderDetails?.orderpatientdetails?.[0]?.patientInitials}</p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Date of Birth</p>
          <p className="text-[#555] font-medium mt-2">{orderDetails?.orderpatientdetails?.[0]?.dob}</p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Gender</p>
          <p className="text-[#555] font-medium mt-2">{orderDetails?.orderpatientdetails?.[0]?.gender}</p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Options</p>
          <p className="text-[#555] font-medium mt-2">
            {orderDetails?.orderpatientdetails?.[0]?.options}
          </p>
        </div>
        <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Clinical Conditions</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.orderclinicalconditions?.[0] || {}).map((element, index) => (
              (orderDetails?.orderclinicalconditions?.[0][element]) ? (<span key={index}>
                {displayOptions.options[0][element]}
                <br /> {/* Add a line break */}
              </span>) : null
            ))}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Patient Concern </p>
          <p className="text-[#555] font-medium mt-2">
            {orderDetails?.orderpatientdetails?.[0]?.patientConcerns}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Upload photographs</p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "uploadData") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Intra Oral Scans</p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "internalScan") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Intra Oral Scans</p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.assets?.map(function (key, index) {
              if (key.fileType === "internalScan2") {
                return (
                  <div className="cursor-pointer" onClick={() => openImageModal(key.s3Url)}>
                    <img
                      key={index} // Don't forget to provide a unique key for each element in the array
                      className="h-7 w-7 object-contain"
                      src={key.s3Url}
                      alt=""
                    />
                  </div>
                );
              }
              return null; // Return null if there's no image URL for the key
            })}
          </div>
        </div>
        <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Treatment Goals</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertreatmentgoals?.[0] || {}).map((element, index) => (
              (orderDetails?.ordertreatmentgoals?.[0][element]) ? (<span key={index}>
                {displayOptions.treatmentGoals[0][element]}{displayOptions.midline[0][element]}
                <br /> {/* Add a line break */}
              </span>) : ''
            ))}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">
            Tooth Movement Restrictions (UTN)
          </p>
          <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
            {orderDetails?.ordertooth?.map((element, index) => (
              <span key={index}>
                {element.toothNumber}
                <br /> {/* Add a line break */}
              </span>
            ))}
          </div>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">
            Inter Proximal Reduction (IPR)
          </p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementInternal[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Overjet Correction</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementOverJet[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Overbite Correction</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementOverBite[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Crossbite Correction</p>
          <p className="text-[#555] font-medium mt-2">
            {Object.keys(orderDetails?.ordertoothmovement?.[0] || {}).map((element, index) =>
              orderDetails?.ordertoothmovement?.[0][element] ? (
                <Fragment key={index}>
                  {displayOptions.tootmovementCrossBite[0][element]}
                  {/* Add a line break */}
                </Fragment>
              ) : null
            )}
          </p>
        </div>
        <div className="TILE pb-4 border-b border-1 border-dotted border-white
 border-opacity-20">
          <p className="text-[15px] text-white">Special Instrcutions</p>
          <p className="text-[#555] font-medium mt-2">
            {orderDetails?.ordertoothmovement?.[0]?.specialInstructions}
          </p>
        </div>
        {isImageModalOpen && (
          <div className="image-modal">
            <Modal
              isOpen={isImageModalOpen}
              onRequestClose={() => setIsImageModalOpen(false)}
              contentLabel="Custom Modal"
              className="image-modal"
              // style={customStyles}
              ariaHideApp={false}
            >
              <>
                <div className="MODAL-BODY flex flex-col h-full">
                  <button
                    className="w-full text-right"
                    onClick={() => closeImageModal()}
                  >
                    <AiOutlineClose className="ml-auto" />
                  </button>
                  <img
                    className="h-full w-full object-contain"
                    src={selectedImage}
                    alt=""
                  />
                </div>
              </>
            </Modal>
          </div>
        )}
      </div>
    </>
  );
};
export const AssignServiceProvider = ({ serviceObjId, orderObjId }) => {

  const { getAccessTokenSilently } = useAuth0();
  const [getServiceProviderByService] = useGetServiceProviderByServiceMutation();
  const [getServiceProviderOrders] = useGetServiceProviderOrdersMutation();
  const [boxSelected, setBoxSelected] = useState("");
  const [isOrderAssigned, setIsOrderAssigned] = useState(false);
  const [isOrderConfirm, setIsOrderConfirm] = useState(false);
  const [serviceProviderData, setServiceProviderData] = useState([]);
  const [serviceProviderOrdersData, setServiceProviderOrdersData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const getServiceProviderByOrderAndServiceData = async (accessToken, serviceObjId, orderObjId) => {

    const respOrder = await getServiceProviderOrders({ accessToken, orderObjId }).unwrap();
    if (respOrder.data.length > 0) {
      // debugger
      setServiceProviderOrdersData(respOrder.data);
      // debugger
    } else {
      const respServ = await getServiceProviderByService({ accessToken, serviceObjId }).unwrap();
      if (respServ) {
        // debugger
        setServiceProviderData(respServ.data);
        // debugger
      }
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getServiceProviderByOrderAndServiceData(accessToken, serviceObjId, orderObjId);
    }
  };

  useEffect(() => {
    if (isUpdated === true) {
      readOrderInfo();
    }
  }, [isUpdated]);

  useEffect(() => {
    readOrderInfo();
  }, []);

  const prepareServicePrCardsData = () => {
    if (serviceProviderData?.length) {
      const arrayOfData = [];
      // debugger;
      // Create HTML elements based on servicesData
      serviceProviderData.map((eachCategory) =>
        arrayOfData.push(
          <ServiceCarouselTile
            value={eachCategory.count}
            header={eachCategory.groupName}
            details={eachCategory.users}
            isAdmin={true}
            boxSelected={boxSelected}
            setBoxSelected={setBoxSelected}
          />
        )
      );
      return arrayOfData;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  // const numberOfSliders = [

  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,

  // ];


  const selectedCategoryCardsData = () => {
    if (serviceProviderData?.length) {
      const arrayOfData = [];
      // debugger;
      // Create HTML elements based on servicesData
      serviceProviderData.filter((item) => item.groupName === boxSelected)[0]?.users?.map((eachCategory) =>
        arrayOfData.push(
          <ServiceProviderCard
            firstName={eachCategory?.details?.firstName}
            lastName={eachCategory?.details?.lastName}
            crowdHireId={eachCategory?.details?.crowdHireId}
            serviceDescription={eachCategory?.details?.serviceDetails[0]?.serviceDescription}
            specialisation={eachCategory?.details?.serviceDetails[0]?.specialisation}
            user_id={eachCategory.user_id}
            userServiceObjId={eachCategory?.details?.userServiceDetails[0]?._id}
            actualPrice={eachCategory?.details?.userServiceDetails[0]?.actualPrice}
            offerPrice={eachCategory?.details?.userServiceDetails[0]?.offerPrice}
            portalPrice={eachCategory?.details?.userServiceDetails[0]?.portalPrice}
            currency={eachCategory?.details?.currency[0]}
            setIsUpdated={setIsUpdated}
            serviceProviderOrdersData={serviceProviderOrdersData}
            orderObjId={orderObjId}
            isOrderAssigned={isOrderAssigned}
            setIsOrderAssigned={setIsOrderAssigned}
            isOrderConfirm={isOrderConfirm}
            setIsOrderConfirm={setIsOrderConfirm}
          />
        )
      );
      return arrayOfData;
    }
    return [];
    // Return null if servicesData is empty or null
  };
  // const numberOfSlidersMobile = [
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  //   <ServiceProviderCard
  //     isOrderAssigned={isOrderAssigned}
  //     setIsOrderAssigned={setIsOrderAssigned}
  //     isOrderConfirm={isOrderConfirm}
  //     setIsOrderConfirm={setIsOrderConfirm}
  //   />,
  // ];

  return (
    // "No Data to Display"
    <div>
      {serviceProviderOrdersData.length > 0 ? (
        <div>
          <p className="text-2xl font-semibold text-white my-4">
            Assigned Service Providers
          </p>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-8">
            {
              serviceProviderOrdersData?.map((eachCategory) => {
                return (<ServiceProviderCard
                  crowdHireId={eachCategory?.userDetails[0]?.crowdHireId}
                  serviceDescription={eachCategory?.["serviceObjId"]?.serviceDescription}
                  specialization={eachCategory?.details?.serviceDetails[0]?.specialization}
                  user_id={eachCategory.user_id}
                  actualPrice={eachCategory?.userServiceDetails[0]?.actualPrice}
                  offerPrice={eachCategory?.userServiceDetails[0]?.offerPrice}
                  portalPrice={eachCategory?.userServiceDetails[0]?.portalPrice}
                  currency={eachCategory?.orderCurrency}
                  setIsUpdated={setIsUpdated}
                  serviceProviderOrdersData={serviceProviderOrdersData}
                  orderObjId={orderObjId}
                  isOrderAssigned={isOrderAssigned}
                  setIsOrderAssigned={setIsOrderAssigned}
                  isOrderConfirm={isOrderConfirm}
                  setIsOrderConfirm={setIsOrderConfirm}
                />)
              })
            }

          </div>
        </div>
      ) : (
        <div>
          <p className="text-2xl font-semibold text-white">
            Choose Service Provider Category
          </p>
          <div className="SERVICE-PROVIDER-CAROUSEL">
            <div className="CAROUSEL-DESKTOP md:block hidden">
              <CustomCarousel
                // rows and columns you want per slide with x and y gaps in pixels
                rows={1}
                cols={4}
                gapX={20}
                gapY={20}
                completeSliders={prepareServicePrCardsData()}
                numberOfCardsShownInSingleSlide={4}
                handleCardOnClick={(card) => {
                  console.log("clicked", card);
                }}
              />
            </div>
            <div className="CAROUSEL-MOBILE md:hidden block">
              <CustomCarousel
                // rows and columns you want per slide with x and y gaps in pixels
                rows={1}
                cols={1}
                gapX={20}
                gapY={20}
                completeSliders={prepareServicePrCardsData()}
                numberOfCardsShownInSingleSlide={5}
                handleCardOnClick={(card) => {
                  console.log("clicked", card);
                }}
              />
            </div>
          </div>
          {boxSelected && (
            <div>
              <div>
                <p className="text-[#555] text-2xl font-semibold my-4">
                  Service Providers
                </p>
              </div>
              <div className="SERVICE-PROVIDER-CAROUSEL">
                <div className="CAROUSEL-DESKTOP md:block hidden">
                  <CustomCarousel
                    // rows and columns you want per slide with x and y gaps in pixels
                    rows={1}
                    cols={4}
                    gapX={20}
                    gapY={20}
                    completeSliders={selectedCategoryCardsData()}
                    numberOfCardsShownInSingleSlide={4}
                    handleCardOnClick={(card) => {
                      console.log("clicked", card);
                    }}
                  />
                </div>
                <div className="CAROUSEL-MOBILE md:hidden block">
                  <CustomCarousel
                    // rows and columns you want per slide with x and y gaps in pixels
                    rows={1}
                    cols={1}
                    gapX={20}
                    gapY={20}
                    completeSliders={selectedCategoryCardsData()}
                    numberOfCardsShownInSingleSlide={5}
                    handleCardOnClick={(card) => {
                      console.log("clicked", card);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export const OrderSpCommunications = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readCommOrders] = useReadCommOrdersMutation();
  const [commData, setCommData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [concern, setConcern] = useState("");
  const [concernDescription, setConcernDescription] = useState("");
  const [isConcernSubmitted, setIsConcernSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  console.log(commData, "commData")

  const isFieldRequired = (fieldName) => {
    const requiredFields = [

    ];
    return requiredFields.includes(fieldName);
  };

  const getReadCommData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await readCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 1,
    });
    if (resp.data.type === 1) {
      console.log(resp.data, 'setCommData')
      setCommData(resp.data.data)
    }

  }

  const submitConcern = async () => {

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await saveCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 0,
      commStatus: 1,
      readStatus: 0,
      imageurl: imageUrl,
      //parentObjId: null,
      communicationTile: concern,
      communicationText: concernDescription
    })
    if (resp?.data?.type === 1) {
      GetToast("Conren Saved");
      setModalIsOpen(false);
      getReadCommData();
    }

    // const data = {
    //   concern: concern,
    //   concernDescription: concernDescription,
    // };
    // console.log(data);

  };

  const validateConcern = (value) => {
    return value?.trim().length > 0;
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "concern") setConcern(value);
    if (name === "concernDescription") setConcernDescription(value);
  };

  useEffect(() => {
    getReadCommData();
  }, [])

  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-white text-2xl font-medium">Concern</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          Add New Concern
        </button>
      </div>
      {commData?.map((item) => (
        <ConcernCard
          item={item}
          getReadCommData={getReadCommData}
          orderObjId={data?.orderObjId}
        />
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setModalIsOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">
                  Add New Concern
                </p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <AttachUpload
                    setImageUrl={setImageUrl}
                    imageUrl={imageUrl}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export const OrderDentistCommunications = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readCommOrders] = useReadCommOrdersMutation();
  const [commData, setCommData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [concern, setConcern] = useState("");
  const [concernDescription, setConcernDescription] = useState("");
  const [isConcernSubmitted, setIsConcernSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  console.log(commData, "commData")

  const isFieldRequired = (fieldName) => {
    const requiredFields = [

    ];
    return requiredFields.includes(fieldName);
  };

  const getReadCommData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await readCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 2,
    });
    if (resp.data.type === 1) {
      setCommData(resp.data.data)
    }

  }

  const submitConcern = async () => {

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await saveCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 0,
      commStatus: 1,
      readStatus: 0,
      imageurl: imageUrl,
      //parentObjId: null,
      communicationTile: concern,
      communicationText: concernDescription
    })
    if (resp?.data?.type === 1) {
      GetToast("Conren Saved");
      setModalIsOpen(false);
      getReadCommData();
    }

    // const data = {
    //   concern: concern,
    //   concernDescription: concernDescription,
    // };
    // console.log(data);

  };

  const validateConcern = (value) => {
    return value?.trim().length > 0;
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "concern") setConcern(value);
    if (name === "concernDescription") setConcernDescription(value);
  };

  useEffect(() => {
    getReadCommData();
  }, [])

  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-white text-2xl font-medium">Concern</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          Add New Concern
        </button>
      </div>
      {commData?.map((item) => (
        <ConcernCard
          item={item}
          getReadCommData={getReadCommData}
          orderObjId={data?.orderObjId}
        />
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setModalIsOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">
                  Add New Concern
                </p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <AttachUpload
                    setImageUrl={setImageUrl}
                    imageUrl={imageUrl}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
const tabNamesArray = [
  "Order Details",
  "Assign Service Provider",
  "Order Communications Service Provider",
  "Order Communications Dentist"
];

function OrderDetailsAdmin({ userRole }) {
  const location = useLocation();
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active='Orders'
      />
      <OrderTab data={location.state} />
    </div>
  );
}

export default OrderDetailsAdmin;

export const OrderTab = (data) => {

  // debugger
  const outputObject = {
    "Order Details": <OrderDetailSummary data={data.data} />,
    "Assign Service Provider": <AssignServiceProvider serviceObjId={data.data.serviceObjId} orderObjId={data.data.orderObjId} />,
    "Order Communications Service Provider": <OrderSpCommunications data={data.data} />,
    "Order Communications Dentist": <OrderDentistCommunications data={data.data} />
  };
  const { user, isAuthenticated } = useAuth0();
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const personalDetails = useSelector((state)=> state.personalDetails);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
            CH00001
            <img
              className="relative top-[4px] right-0"
              src={greenTick}
              alt=""
            />
          </button>
          <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-white ">Orders</p>
        </div>
      </div>

      <div className="bg-secondary p-4 rounded-md flex-1 flex flex-col">
        <p className="text-xl font-medium text-white my-4">Order Details</p>
        <div className="Tabs flex-1 h-full flex flex-col">
          {/* Tab nav */}
          <ul className="NAV flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-[#222026] border-opacity-10 py-4">
            {tabNamesArray.map((title, i) => {
              return (
                <li
                  key={i + 1}
                  className="relative"
                  onClick={() => handleTabSuper(title)}
                >
                  <div
                    className={
                      activeTab === title
                        ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-primary before:left-0 before:-bottom-4"
                        : "hover:text-primary cursor-pointer text-[#999] font-normal"
                    }
                  >
                    {title}
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="outlet md:h-full flex-1 h-[50vh] flex flex-col overflow-auto pt-4 ">
            {Object.keys(outputObject).map((key) => {
              if (tabNamesArray.includes(key)) {
                return (
                  <>{activeTab === key ? <>{outputObject[key]}</> : null}</>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
