import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useSaveServicesMutation,
  useUpdateServicesMutation,
  useGetServicesMutation,
  useSaveQuestionMutation,
  useSaveAnswerMutation,
  useUpdateAnswerMutation,
  useUpdateQuestionMutation,
} from "../../services/services";
import { useDeleteFileMutation, useSaveFileMutation } from "../../services/uploadFiles";
import DropdownField from "../InputField-Comps/DropdownField";
import FileUploadComponent from "../InputField-Comps/FilePondField";
import { GetToast } from "../Toast-Comps/GetToast";
import Modal from "react-modal";
import { AiOutlineClose, AiOutlineLoading } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { CategoryTextAreaField } from "../Services-Comps/InputFieldsCategory";
import TextInputField from "../InputField-Comps/TextInputField";
import { NumberInputField } from "../Services-Comps/InputFieldsServicesTab";

const InputFieldQuestionTab = ({
  setModalIsOpen,
  isEditModal,
  editingServicesRow,
  getData,
  serviceData,
}) => {
  const questionTypeOptions = [
    {
      label: "Check Box",
      value: "checkBox",
    },
    {
      label: "Radio Button",
      value: "radioButton",
    },
  ];
  const { getAccessTokenSilently } = useAuth0();
  const initialQuestionDetails = {
    questionType: "",
    sortOrder: "",
    question: "",
    options: [],
  };

  const [optionText, setOptionText] = useState("");

  const [saveQuestion] = useSaveQuestionMutation();
  const [updateQuestion] = useUpdateQuestionMutation();
  const [saveAnswer] = useSaveAnswerMutation();
  const [updateAnswer] = useUpdateAnswerMutation();

  const [questionDetails, setQuestionDetails] = useState(initialQuestionDetails);

  const [currentQuestion, setCurrentQuestion] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setQuestionDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateSortOrder = (value) => {
    // Your name validation logic goes here
    // Return true if the value is valid, false otherwise
    // For example:
    return Number(value) ? true : false;
  };

  const handleOptionChange = (event, index) => {
    const { value } = event.target;

    // Create a copy of the options array
    const updatedOptions = [...questionDetails.options];

    // Update the option at the specified index with the new value
    updatedOptions[index] = { optionName: value };

    // Update the state with the new options array
    setQuestionDetails((prev) => ({
      ...prev,
      options: updatedOptions,
    }));
  };

  const handleAddOption = () => {
    const newOptions = [
      ...questionDetails?.options,
      {
        optionName: optionText,
      },
    ];

    setQuestionDetails((prev) => ({
      ...prev,
      options: newOptions,
    }));
    setOptionText("");
  };

  const handlDeleteOption = (index) => {
    const updatedOption = [...questionDetails?.options];

    setQuestionDetails((prev) => ({
      ...prev,
      options: updatedOption.filter((_, idx) => idx !== index),
    }));
  };

  useEffect(() => {
    // questionType: "",
    // question: "",
    // options: [],
    if (editingServicesRow) {
      setQuestionDetails({
        _id: editingServicesRow?._id,
        questionType: editingServicesRow?.questionType || "checkBox",
        question: editingServicesRow?.question,
        sortOrder: editingServicesRow?.sortOrder,
        options: editingServicesRow?.answers?.map((item) => ({
          answerObjId: item?.answerObjId,
          optionName: item?.option,
        })),
      });

      setCurrentQuestion({
        _id: editingServicesRow?._id,
        questionType: editingServicesRow?.questionType || "checkBox",
        question: editingServicesRow?.question,
        options: editingServicesRow?.answers?.map((item) => ({
          answerObjId: item?.answerObjId,
          optionName: item?.option,
        })),
      });
    }
  }, [editingServicesRow]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    if (isEditModal) {
      if (currentQuestion.question !== questionDetails?.question || currentQuestion?.sortOrder !== questionDetails?.sortOrder) {
        const res = await updateQuestion({
          accessToken: accessToken,
          _id: questionDetails?._id,
          question: questionDetails?.question,
          sortOrder: questionDetails?.sortOrder,
        }).unwrap();

        if (res?.type === 1) {
          GetToast("Question Successfully Saved!");
        }
      }

      questionDetails?.options?.map(async (item) => {
        const resAns = await updateAnswer({
          accessToken: accessToken,
          answerObjId: item?.answerObjId,
          option: item?.optionName,
        });

        if (resAns?.type === 1) {
          GetToast("Answer Successfully Saved!");
        }
      });
    } else {
      const res = await saveQuestion({
        accessToken: accessToken,
        question: questionDetails?.question,
        sortOrder: questionDetails?.sortOrder,
      }).unwrap();

      if (res?.type === 1) {
        GetToast("Question Successfully Saved!");
        questionDetails?.options?.map(async (item) => {
          const resAns = await saveAnswer({
            _id: res?.data[0]?._id,
            accessToken: accessToken,
            option: item?.optionName,
          });

          if (resAns?.type === 1) {
            GetToast("Answer Successfully Saved!");
          }
        });
      }
    }
    setModalIsOpen(false);
    await getData();
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div>
            <DropdownField
              labelText={"Select Options Type"}
              name={"questionType"}
              labelClass={"text-[16px] leding-4"}
              handleInputChange={handleChange}
              labelColor={"#777"}
              value={questionDetails?.questionType}
              options={questionTypeOptions}
              isFieldRequired={true}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelText={"Question"}
              labelColor={"#777"}
              // errorMessage={"this is error"}
              placeholder={"Enter Question"}
              name={"question"}
              value={questionDetails?.question}
              handleInputChange={handleChange}
              // isSubmitted={isSubmitted}
              // validateName={validateName}
              isFieldRequired={true}
            />
          </div>
          <div className="relative my-2">
            <p className="text-white text-[16px] loading-4">Options</p>
            <div className="flex justify-between items-center my-4">
              <input
                type="text"
                placeholder="Add Option"
                value={optionText}
                onChange={(e) => {
                  e.preventDefault();
                  setOptionText(e.target.value);
                }}
                className="border p-2 w-full text-[#fff] bg-black text-[16px] leading-4 rounded-md h-10"
              />
              <button
                className="font-medium text-[17px] left-[18px] h-fit text-white py-2 px-4 ml-3 bg-[#ff7e00] rounded-lg"
                type="button"
                onClick={handleAddOption}
              >
                +
              </button>
            </div>
          </div>
          {questionDetails?.options?.length > 0 &&
            questionDetails?.options.map((option, index) => (
              <div className="flex items-center justify-between gap-4 mb-3">
                <div className="w-full bg-[#3c3f3f] p-2 text-[#fff]  text-[16px] leading-4 rounded-md h-10">
                  {option?.optionName}
                </div>
                <button className="font-medium text-[17px] text-white py-3 px-3 bg-red-500 rounded-lg">
                  <MdDelete type="button" onClick={() => handlDeleteOption(index)} />
                </button>
              </div>
            ))}
            <div className="relative">
            <NumberInputField
              labelText={"Sort Order*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Sort Order"}
              name={"sortOrder"}
              value={questionDetails?.sortOrder}
              handleInputChange={handleChange}
              validateName={validateSortOrder}
              isFieldRequired={true}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldQuestionTab;
