import React from "react";
import { Flip, Slide, toast, ToastContainer } from "react-toastify";

export const GetToast = (message, status) => {
    toast(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip,
        style: {
            background: status === "error" ? "red" : "#FF8503",
            color: '#fff',
            borderRadius: "none",
            // padding: '10px',
            fontSize: '16px',
            // width: "97vw",
            margin: '0 !important',
            minHeight: "32px",
            padding: "4px 8px"
        },
        progressStyle: {
            background: '#ffffff',
        }
    });
}