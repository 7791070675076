import React, { useState } from "react";
import { Checkbox } from "../InputField-Comps/CheckboxField";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useDeleteOrderToothDataMutation,
  useDeleteOrderTreatmentGlsDataMutation,
  useSaveOrderToothDataMutation,
  useSaveOrderTreatmentGlsDataMutation,
  useUpdateOrderToothDataMutation,
  useUpdateOrderTreatmentGlsDataMutation,
} from "../../services/orders";
import { useEffect } from "react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import RangeInputField from "../InputField-Comps/RangeInputField";

function OrthodonticCaseDetailsSection({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
}) {
  const [midline, setMidline] = useState(orderCaseData?.midline || []);
  const [molarOcclusalGoals, setMolarOcclusalGoals] = useState(
    orderCaseData?.molarOcclusalGoals || ""
  );
  const [selectedValue, setSelectedValue] = useState(orderCaseData?.archesToTreat || []);

  const [optionsValue, setOptionsValue] = useState(orderCaseData?.options || "");
  const [clinicalValues, setClinicalValues] = useState(orderCaseData?.clinicalConditions || []);

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  const [teethNotToBeMoved, setTeethNotToBeMoved] = useState(
    orderCaseData?.teethNeedToMove?.needToMove === null
      ? ""
      : orderCaseData?.teethNeedToMove?.needToMove
      ? "Yes"
      : "No"
  );
  const [teethNotToBeMovedSelectedTeeth, setTeethNotToBeMovedSelectedTeeth] = useState(
    orderCaseData?.teethNeedToMove?.teeth || []
  );
  const [attachmentsAllowed, setAttachmentsAllowed] = useState(
    orderCaseData?.attachmentsAllowed === null
      ? ""
      : orderCaseData?.attachmentsAllowed
      ? "Yes"
      : "No"
  );
  const [retainerRequired, setRetainerRequired] = useState(
    orderCaseData?.retainerRequired === null ? "" : orderCaseData?.retainerRequired ? "Yes" : "No"
  );

  const [iPRValue, setIPRValue] = useState(orderCaseData?.ipr || "");
  const [overjetCorrection, setOverjetCorrection] = useState(
    orderCaseData?.overjetCorrection?.option || ""
  );
  const [overjetCorrectionImprove, setOverjetCorrectionImprove] = useState(
    orderCaseData?.overjetCorrection?.improveLength || 0
  );
  const [overbiteCorrection, setOverbiteCorrection] = useState(
    orderCaseData?.overbiteCorrection?.option || ""
  );
  const [overbiteCorrectionImprove, setOverbiteCorrectionImprove] = useState(
    orderCaseData?.overbiteCorrection?.improveLength || 0
  );

  const [crossBiteValue, setCrossBiteValue] = useState(orderCaseData?.crossbiteCorrection || "");
  const [specialInstructions, setSpecialInstructions] = useState(
    orderCaseData?.additionalInstructions || ""
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "specialInstructions") setSpecialInstructions(value);
    if (name === "overjetCorrectionImprove") setOverjetCorrectionImprove(value);
    if (name === "overbiteCorrectionImprove") setOverbiteCorrectionImprove(value);
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["specialInstructions"];

    return requiredFields.includes(fieldName);
  };

  const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleAllCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setMidline((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setMidline((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedValue((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setSelectedValue((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handleteethNotToBeMovedChange = (event) => {
    setTeethNotToBeMoved(event.target.value);
  };

  const handleAttachmentsAllowedChange = (event) => {
    setAttachmentsAllowed(event.target.value);
  };

  const handleRetainerRequiredChange = (event) => {
    setRetainerRequired(event.target.value);
  };

  const handleIPRValueCheckboxChange = (event) => {
    setIPRValue(event.target.value);
  };

  const handleCrossBiteValueCheckboxChange = (event) => {
    setCrossBiteValue(event.target.value);
  };

  const handleOverbiteCorrectionCheckboxChange = (event) => {
    setOverbiteCorrection(event.target.value);
  };

  const handleOverjetCorrectionCheckboxChange = (event) => {
    setOverjetCorrection(event.target.value);
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const submitTreatmentGoals = async () => {
    // if (treatmentGlData.length > 0) {
    //   const archesBool = {
    //     archesToTreatUpper: false,
    //     archesToTreatLower: false,
    //   };
    //   const midlineBool = {
    //     midlineOptimise: false,
    //     midlineMaintain: false,
    //     midlineMoverUpper: false,
    //     midlineMoverLower: false,
    //   };
    //   const multiSelectOptionsBool = {
    //     class1MolarOcclsion: false,
    //     maintainMolarRel: false,
    //     c2cCorrection: false,
    //   };
    //   // for (const key of treatmentGlData) {
    //   //   if (archesBool.hasOwnProperty(key)) {
    //   //     archesBool[key] = true;
    //   //   }
    //   // }
    //   for (const key of treatmentGlData) {
    //     if (midlineBool.hasOwnProperty(key)) {
    //       midlineBool[key] = true;
    //     }
    //   }
    //   for (const key of treatmentGlData) {
    //     if (multiSelectOptionsBool.hasOwnProperty(key)) {
    //       multiSelectOptionsBool[key] = true;
    //     }
    //   }
    //   const isUpdated = arraysHaveSameValues(initialOrdertreatmentgoals, treatmentGlData);
    //   const data = {
    //     arches:
    //       selectedValue === "archesToTreatUpper"
    //         ? { archesToTreatUpper: true, archesToTreatLower: false }
    //         : { archesToTreatUpper: false, archesToTreatLower: true },
    //     multiSelectOptions: multiSelectOptionsBool,
    //     midline: midlineBool,
    //   };
    //   const accessToken = await getAccessTokenSilently({
    //     authorizationParams: {
    //       audience: process.env.REACT_APP_AUDIENCE_URL,
    //     },
    //   });
    //   if (orderByData?.ordertreatmentgoals?.length === 0) {
    //     const resp = await saveOrderTreatmentGlsData({
    //       accessToken,
    //       orderObjId: orderByData?._id,
    //       treatmentGoals: data,
    //     });
    //     if (resp?.data?.type === 1) {
    //       //setTreatmentGlData(data);
    //       //submitedTreatmentGoalsData(data);
    //       setOrderUpdated(true);
    //       setActiveTab("Upload Files");
    //     }
    //   } else {
    //     if (!isUpdated) {
    //       const resp = await updateOrderTreatmentGlsData({
    //         accessToken,
    //         _id: orderByData?.ordertreatmentgoals[0]?._id,
    //         treatmentGoals: data,
    //       });
    //       if (resp?.data?.type === 1) {
    //         //setTreatmentGlData(data);
    //         //submitedTreatmentGoalsData(data);
    //         setOrderUpdated(true);
    //         setActiveTab("Upload Files");
    //       }
    //     } else {
    //       setActiveTab("Upload Files");
    //     }
    //   }
    // }
    submitOrderData({
      options: optionsValue,
      clinicalConditions: clinicalValues,
      archesToTreat: selectedValue,
      molarOcclusalGoals: molarOcclusalGoals,
      midline: midline,
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      teethNeedToMove: {
        needToMove: teethNotToBeMoved ? teethNotToBeMoved === "Yes" : null,
        teeth: teethNotToBeMovedSelectedTeeth,
      },
      ipr: iPRValue,
      overjetCorrection: {
        option: overjetCorrection,
        improveLength: overjetCorrectionImprove,
      },
      overbiteCorrection: {
        option: overbiteCorrection,
        improveLength: overbiteCorrectionImprove,
      },
      crossbiteCorrection: crossBiteValue,
      attachmentsAllowed: attachmentsAllowed ? attachmentsAllowed === "Yes" : null,
      retainerRequired: retainerRequired ? retainerRequired === "Yes" : null,
      additionalInstructions: specialInstructions,
    });
    setActiveTab("Upload Files");
  };

  const handleOptionsCheckboxChange = (event) => {
    setOptionsValue(event.target.value);
  };

  const handleClinicalValuesCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    if (checked) {
      setClinicalValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setClinicalValues((prevSelectedValues) =>
        prevSelectedValues.filter((selectedValue) => selectedValue !== value)
      );
    }
  };

  const handleMolarOcclusalGoalsCheckboxChange = (event) => {
    setMolarOcclusalGoals(event.target.value);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="w-10/12">
        <div>
          <p className="font-medium text-[24px] text-white mb-4">Options</p>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mb-6">
            <div className="flex items-center">
              <CircularCheckbox
                value="Orthodontic Treatment Planning (OTP)"
                checked={optionsValue === "Orthodontic Treatment Planning (OTP)"}
                onChange={handleOptionsCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Orthodontic Treatment Planning (OTP)</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="OTP + STL modeller for 3D printing"
                checked={optionsValue === "OTP + STL modeller for 3D printing"}
                onChange={handleOptionsCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">OTP + STL files for 3D printing</p>
            </div>
            {/* <div className="flex items-center">
            <CircularCheckbox
              value="OTP + STL + 3D printing models"
              checked={optionsValue === "OTP + STL + 3D printing models"}
              onChange={handleOptionsCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">OTP + STL + 3D printing models</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="OTP + STL + 3D printing models + Aligners"
              checked={optionsValue === "OTP + STL + 3D printing models + Aligners"}
              onChange={handleOptionsCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">OTP + STLs + 3D printed models + Aligners</p>
          </div> */}
          </div>
        </div>
        <div className="mb-6">
          <p className="font-medium text-[24px] text-white mt-2 mb-4">Clinical Conditions</p>
          <div className="">
            <div className="grid md:grid-cols-4 md:grid-rows-4 grid-cols-1  gap-x-8 gap-y-4 text-[16px] text-white">
              <div className="flex items-center">
                <CircularCheckbox
                  value="Crowding"
                  checked={clinicalValues.includes("Crowding")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Crowding</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Spacing"
                  checked={clinicalValues.includes("Spacing")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Spacing</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Class 2 Division1"
                  checked={clinicalValues.includes("Class 2 Division1")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Class 2 Division1</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Uneven Smile"
                  checked={clinicalValues.includes("Uneven Smile")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Uneven Smile</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Open Bite"
                  checked={clinicalValues.includes("Open Bite")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Open Bite</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Deep Bite"
                  checked={clinicalValues.includes("Deep Bite")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Deep Bite</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Class 2 Division 2"
                  checked={clinicalValues.includes("Class 2 Division 2")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Class 2 Division 2</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Anterior Cross Bite"
                  checked={clinicalValues.includes("Anterior Cross Bite")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Anterior Cross Bite</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Posterior Cross Bite"
                  checked={clinicalValues.includes("Posterior Cross Bite")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Posterior Cross Bite</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Narrow Arch"
                  checked={clinicalValues.includes("Narrow Arch")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Narrow Arch</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Increased Overjet"
                  checked={clinicalValues.includes("Increased Overjet")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Increased Overjet</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Misshapened Teeth"
                  checked={clinicalValues.includes("Misshapened Teeth")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Misshapened Teeth</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Flared Teeth"
                  checked={clinicalValues.includes("Flared Teeth")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Flared Teeth</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Others"
                  checked={clinicalValues.includes("Others")}
                  onChange={handleClinicalValuesCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Others</p>
              </div>
            </div>
            {!(clinicalValues.length > 0) ? (
              <p className="text-sm text-red-500">Select at least one</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <p className="font-medium text-white text-[24px] mb-4">Arches to treat</p>
          <div className="flex md:gap-32 gap-4 items-center mb-6">
            <div className="flex items-center">
              <CircularCheckbox
                value="Upper"
                checked={selectedValue.includes("Upper")}
                onChange={handleCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Upper</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="Lower"
                checked={selectedValue.includes("Lower")}
                onChange={handleCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Lower</p>
            </div>
          </div>
        </div>
        <div>
          <p className="font-medium text-white text-[24px] mb-4">Molar Occlusal Goals</p>
          <div className="font-medium text-white grid grid-rows md:gap-5 gap-4 mb-6">
            <div className="flex items-center">
              <CircularCheckbox
                value="Class I Molar Occulsion"
                checked={molarOcclusalGoals === "Class I Molar Occulsion"}
                onChange={handleMolarOcclusalGoalsCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Class I Molar Occulsion</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="Maintain Molar Relationship"
                checked={molarOcclusalGoals === "Maintain Molar Relationship"}
                onChange={handleMolarOcclusalGoalsCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Maintain Molar Relationship</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="Canine to Canine correction (Social 6 only)"
                checked={molarOcclusalGoals === "Canine to Canine correction (Social 6 only)"}
                onChange={handleMolarOcclusalGoalsCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Canine to Canine correction (Social 6 only)</p>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <p className="font-medium text-[24px] text-white mb-4">Midline</p>
          <div className="">
            <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-0 gap-4 text-white text-[16px]">
              <div className="flex items-center">
                <CircularCheckbox
                  value="Optimize"
                  checked={midline.includes("Optimize")}
                  onChange={handleAllCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Optimize</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Maintain"
                  checked={midline.includes("Maintain")}
                  onChange={handleAllCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Maintain</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Move Upper Only"
                  checked={midline.includes("Move Upper Only")}
                  onChange={handleAllCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Move Upper Only</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Move lower only"
                  checked={midline.includes("Move lower only")}
                  onChange={handleAllCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Move lower only</p>
              </div>
            </div>
            {midline.includes("midlineOptimise") ||
            midline.includes("midlineMaintain") ||
            midline.includes("midlineMoverUpper") ||
            midline.includes("midlineMoverLower") ? (
              <></>
            ) : (
              <p className="text-red-500 text-sm">Select at least one</p>
            )}
          </div>
        </div>

        <div className="text-white">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth{" "}
            {isFieldRequired("hasMissingTeeth") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mx-5 mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={setMissingTeethSelectedTeeth}
                defaultNotation={"FDI"}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <div>
          <div className="text-white flex my-8">
            Are any teeth that should not be moved?{" "}
            {isFieldRequired("teethNotToBeMoved") ? <span className="text-red-500">*</span> : ""}
            <div className="flex items-center mx-3">
              <CircularCheckbox
                value="Yes"
                checked={teethNotToBeMoved === "Yes"}
                onChange={handleteethNotToBeMovedChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Yes</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={teethNotToBeMoved === "No"}
                onChange={handleteethNotToBeMovedChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">No</p>
            </div>
          </div>
          {teethNotToBeMoved === "Yes" && (
            <div className="mx-5">
              <p className="text-white text-[17px] mb-2">
                Please select any teeth that should not be moved
                {isFieldRequired("teethExtractionSelectedTeeth") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <div className="">
                <TeethV2
                  allTeethData={[]}
                  selectedTeeth={teethNotToBeMovedSelectedTeeth}
                  setSelectedTeeth={setTeethNotToBeMovedSelectedTeeth}
                  defaultNotation={"FDI"}
                  notationVisible={false}
                  missingTeeth={missingTeethSelectedTeeth}
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <p className="font-medium text-[24px] text-white my-4">Inter Proximal Reduction (IPR)</p>
          <div className="flex md:gap-36 gap-4 items-center mb-6">
            <div className="flex items-center">
              <CircularCheckbox
                value="Anteriors"
                checked={iPRValue === "Anteriors"}
                onChange={handleIPRValueCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Anteriors</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="Anteriors and Premolars"
                checked={iPRValue === "Anteriors and Premolars"}
                onChange={handleIPRValueCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Anteriors and Premolars</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="All Teeth"
                checked={iPRValue === "All Teeth"}
                onChange={handleIPRValueCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">All Teeth</p>
            </div>
          </div>
          {iPRValue?.trim().length > 0 ? (
            <></>
          ) : (
            <p className="text-sm text-red-500">Select at least one</p>
          )}
        </div>
        <div>
          <p className="font-medium text-[24px] text-white mb-4">Overjet Correction</p>
          <div className="mb-6">
            <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px]">
              <div className="flex items-center">
                <CircularCheckbox
                  value="Optimize"
                  checked={overjetCorrection === "Optimize"}
                  onChange={handleOverjetCorrectionCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Optimize</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Maintain"
                  checked={overjetCorrection === "Maintain"}
                  onChange={handleOverjetCorrectionCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Maintain</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Improve"
                  checked={overjetCorrection === "Improve"}
                  onChange={handleOverjetCorrectionCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Improve</p>
              </div>
            </div>
            {overjetCorrection === "Improve" && (
              <div className="w-1/2 mt-4">
                <RangeInputField
                  labelColor={"#fff"}
                  labelText={"Select improve length"}
                  errorMessage={"Please enter a valid cement gap value"}
                  placeholder={""}
                  htmlFor={"overjetCorrectionImprove"}
                  name={"overjetCorrectionImprove"}
                  value={overjetCorrectionImprove}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateNumber}
                  isSubmitted={isFieldRequired}
                  isFieldRequired={isFieldRequired("overjetCorrection")}
                  min={0}
                  max={5}
                  step={0.5}
                />
              </div>
            )}
            {overjetCorrection.includes("Optimize") ||
            overjetCorrection.includes("Maintain") ||
            overjetCorrection.includes("Improve") ? (
              <></>
            ) : (
              <p className="text-sm text-red-500">Select at least one</p>
            )}
          </div>
        </div>
        <div>
          <p className="font-medium text-[24px] text-white mb-4">Overbite Correction</p>
          <div className="mb-6">
            <div className="grid md:grid-cols-4 grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px]">
              <div className="flex items-center">
                <CircularCheckbox
                  value="Optimize"
                  checked={overbiteCorrection === "Optimize"}
                  onChange={handleOverbiteCorrectionCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Optimize</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Maintain"
                  checked={overbiteCorrection === "Maintain"}
                  onChange={handleOverbiteCorrectionCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Maintain</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Improve"
                  checked={overbiteCorrection === "Improve"}
                  onChange={handleOverbiteCorrectionCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Improve</p>
              </div>
            </div>
            {overbiteCorrection === "Improve" && (
              <div className="w-1/2 mt-4">
                <RangeInputField
                  labelColor={"#fff"}
                  labelText={"Select improve length"}
                  errorMessage={"Please enter a valid cement gap value"}
                  placeholder={""}
                  htmlFor={"overbiteCorrectionImprove"}
                  name={"overbiteCorrectionImprove"}
                  value={overbiteCorrectionImprove}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateNumber}
                  isSubmitted={isFieldRequired}
                  isFieldRequired={isFieldRequired("overbiteCorrection")}
                  min={0}
                  max={5}
                  step={0.5}
                />
              </div>
            )}
            {overbiteCorrection.includes("Optimize") ||
            overbiteCorrection.includes("Maintain") ||
            overbiteCorrection.includes("Improve") ? (
              <></>
            ) : (
              <p className="text-sm text-red-500">Select at least one</p>
            )}
          </div>
        </div>
        <div>
          <p className="font-medium text-[24px] text-white mb-4">Crossbite Correction</p>
          <div className="flex md:gap-36 gap-4 items-center mb-6">
            <div className="flex items-center">
              <CircularCheckbox
                value="Optimize"
                checked={crossBiteValue === "Optimize"}
                onChange={handleCrossBiteValueCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Optimize</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="Maintain"
                checked={crossBiteValue === "Maintain"}
                onChange={handleCrossBiteValueCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Maintain</p>
            </div>
          </div>
          {crossBiteValue?.trim().length > 0 ? (
            <></>
          ) : (
            <p className="text-sm text-red-500">Select at least one</p>
          )}
        </div>
        <div className="text-white flex my-8">
          Attachments allowed?{" "}
          {isFieldRequired("attachmentsAllowed") ? <span className="text-red-500">*</span> : ""}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              checked={attachmentsAllowed === "Yes"}
              onChange={handleAttachmentsAllowedChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              checked={attachmentsAllowed === "No"}
              onChange={handleAttachmentsAllowedChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        <div className="text-white flex my-8">
          Retainer Required?{" "}
          {isFieldRequired("attachmentsAllowed") ? <span className="text-red-500">*</span> : ""}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              checked={retainerRequired === "Yes"}
              onChange={handleRetainerRequiredChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              checked={retainerRequired === "No"}
              onChange={handleRetainerRequiredChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        <div className="mb-6">
          <TextAreaInputField
            labelColor={"#777"}
            labelText={"Special Instructions"}
            errorMessage={"This field is required"}
            placeholder={"Enter Special Instructions"}
            isFieldRequired={isFieldRequired("specialInstructions")}
            htmlFor={"specialInstructions"}
            name={"specialInstructions"}
            value={specialInstructions}
            handleInputChange={handleInputChange}
            // validationFunctionName={validatePatientConcern}
            // isSubmitted={isPatientInitialFormSubmitted}
            rows={4}
            cols={5}
          />
          {specialInstructions?.trim()?.length > 0 ? (
            <></>
          ) : (
            <p className="text-sm text-red-500">This field is required</p>
          )}
        </div>
        <p className="text-base text-white">
          For Cases involving Extractions, Distalizations, Complex tooth movements and Surgical
          Orthodontics, we do not recommend using in house fabricated aligners.
        </p>
      </div>
      <div className="BOTTOM-SECTION mt-8">
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitTreatmentGoals();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrthodonticCaseDetailsSection;
