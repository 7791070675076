import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const softwareCategoryApi = createApi({
  reducerPath: "softwareCategoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_AUDIENCE_URL }),
  endpoints: (builder) => ({
    saveSoftwaresCategory: builder.mutation({
      query: ({ accessToken, serCategroyIcon, softwareCategoryName, sortOrder }) => {
        let formData = {
          softwareCategoryName: softwareCategoryName,
          serCategroyIcon: serCategroyIcon,
          sortOrder: sortOrder,
        };

        return {
          url: "registration/softwarecategory/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateSoftwaresCategory: builder.mutation({
      query: ({ accessToken, _id, serCategroyIcon, softwareCategoryName, sortOrder }) => {
        let formData = {
          _id: _id,
          softwareCategoryName: softwareCategoryName,
          sortOrder: sortOrder,
        };

        if (serCategroyIcon) {
          formData.serCategroyIcon = serCategroyIcon;
        }

        return {
          url: "/registration/softwarecategory/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSoftwaresCategories: builder.mutation({
      query: (accessToken) => ({
        url: "/registration/softwarecategory/list",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getSoftwareCategoryByName: builder.mutation({
      query: ({ accessToken, softwareCategoryName }) => {
        let formData = {
          softwareCategoryName: softwareCategoryName,
        };
        return {
          url: "/registration/softwarecategory/list",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getSoftwaresCategoryList: builder.mutation({
      query: ({ accessToken, _id }) => {
        let formData = {
          _id: _id,
        };

        return {
          url: "/registration/softwarecategory/list",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useSaveSoftwaresCategoryMutation,
  useUpdateSoftwaresCategoryMutation,
  useGetSoftwaresCategoriesMutation,
  useGetSoftwaresCategoryListMutation,
  useGetSoftwareCategoryByNameMutation,
} = softwareCategoryApi;
