import { useEffect, useState } from "react";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { useNavigate } from "react-router-dom";
import {
  useSaveOrderTeethDataMutation,
  useSaveOrderToothMovDataMutation,
  useUpdateOrderToothMovDataMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { TextInputField } from "../Services-Comps/InputFieldsServicesTab";
import DateInputFieldV2 from "../InputField-Comps/DateInputFieldV2";
import { TeethV2 } from "../Teeth-Comps/TeethV2";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";

export const ImplantCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  serviceObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  // debugger
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [saveOrderToothMovData] = useSaveOrderToothMovDataMutation();
  const [updateOrderToothMovData] = useUpdateOrderToothMovDataMutation();
  const [saveOrderTeethData] = useSaveOrderTeethDataMutation();
  const [updateOrderTeethData] = useUpdateOrderToothMovDataMutation();

  // eslint-disable-next-line no-unused-vars
  const [additonalInstructions, setSpecialInstructions] = useState(
    orderCaseData?.additionalInstructions || ""
  );
  const [preferredImplantCompany, setPreferredImplantCompany] = useState(
    orderCaseData?.preferredImplantCompany || ""
  );
  const [implantLine, setImplantLine] = useState(orderCaseData?.implantLine || "");
  const [implantSurgicalKit, setImplantSurgicalKit] = useState(
    orderCaseData?.implantSurgicalKit?.option || ""
  );
  const [guidanceLevel, setGuidanceLevel] = useState(orderCaseData?.guidanceLevel || []);
  const [stackableGuideOptions, setStackableGuideOptions] = useState(
    orderCaseData?.guideType?.additionalStackableOptions || []
  );
  const [servicesRequired, setServicesRequired] = useState(orderCaseData?.servicesRequired || []);
  const [densahBursUsed, setDensahBursUsed] = useState(
    orderCaseData?.implantSurgicalKit?.densahBursUsed === null
      ? ""
      : orderCaseData?.implantSurgicalKit?.densahBursUsed
      ? "Yes"
      : "No"
  );
  const [densahBurGuideRequired, setDensahBurGuideRequired] = useState(
    orderCaseData?.implantSurgicalKit?.densahBurGuideDesired === null
      ? ""
      : orderCaseData?.implantSurgicalKit?.densahBurGuideDesired
      ? "Yes"
      : "No"
  );
  const [flapBeRaised, setFlapBeRaised] = useState(
    orderCaseData?.implantSurgicalKit?.flapBeRaised === null
      ? ""
      : orderCaseData?.implantSurgicalKit?.flapBeRaised
      ? "Yes"
      : "No"
  );
  const [boneReductionNecessary, setBoneReductionNecessary] = useState(
    orderCaseData?.guideType?.incorporateBoneReduction === null
      ? ""
      : orderCaseData?.guideType?.incorporateBoneReduction
      ? "Yes"
      : "No"
  );
  const [conversionDentureAvailable, setConversionDentureAvailable] = useState(
    orderCaseData?.guideType?.conversionDentureAvailable === null
      ? ""
      : orderCaseData?.guideType?.conversionDentureAvailable
      ? "Yes"
      : "No"
  );
  const [digitalConversionDenture, setDigitalConversionDenture] = useState(
    orderCaseData?.guideType?.digitalConversionDentureRequired === null
      ? ""
      : orderCaseData?.guideType?.digitalConversionDentureRequired
      ? "Yes"
      : "No"
  );
  const [prosthesisBeUsed, setProsthesisBeUsed] = useState(
    orderCaseData?.guideType?.prosthesisBeUsed === null
      ? ""
      : orderCaseData?.guideType?.prosthesisBeUsed
      ? "Yes"
      : "No"
  );
  const [abutmentPreferences, setAbutmentPreferences] = useState(
    orderCaseData?.guideType?.abutmentPreferences || ""
  );

  const [surgeryDate, setSurgeryDate] = useState(
    orderCaseData?.surgeryDate ? new Date(orderCaseData?.surgeryDate) : ""
  );
  const [guideTypeValue, setGuideTypeValue] = useState(orderCaseData?.guideType?.option || "");
  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.missingTeeth?.hasMissingTeeth === null
      ? ""
      : orderCaseData?.missingTeeth?.hasMissingTeeth
      ? "Yes"
      : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeeth?.teeth || []
  );
  const [anyTeethMobile, setAnyTeethMobile] = useState(
    orderCaseData?.anyTeethMobile?.anyTeethMobile === null
      ? ""
      : orderCaseData?.anyTeethMobile?.anyTeethMobile
      ? "Yes"
      : "No"
  );
  const [mobileTeeth, setMobileTeeth] = useState(orderCaseData?.anyTeethMobile?.teeth || []);
  const [intendToExtractTeeth, setIntendToExtractTeeth] = useState(
    orderCaseData?.teethToExtract?.teethToExtract === null
      ? ""
      : orderCaseData?.teethToExtract?.teethToExtract
      ? "Yes"
      : "No"
  );
  const [teethExtractionSelectedTeeth, setTeethExtractionSelectedTeeth] = useState(
    orderCaseData?.teethToExtract?.teeth || []
  );
  const [selectedService, setSelectedService] = useState("");
  const [guideTypeTeethSelected, setGuideTypeTeethSelected] = useState(
    orderCaseData?.services?.reduce(
      (acc, curr) => ({ ...acc, [curr?.serviceObjId]: curr?.teeth }),
      {}
    )
  );

  console.log("guideTypeTeethSelected", guideTypeTeethSelected);

  const handleGuidanceLevelCheckboxChange = (event) => {
    setGuidanceLevel(event.target.value);
  };

  const handleStackableGuideOptionsCheckboxChange = (event) => {
    const { value } = event.target;
    if (stackableGuideOptions.includes(value)) {
      // If the checkbox is checked, add the value to the array
      setStackableGuideOptions(stackableGuideOptions.filter((d) => d !== value));
    } else {
      setStackableGuideOptions([...stackableGuideOptions, value]);
    }
  };

  console.log("servicesRequired", servicesRequired);

  const handleservicesRequiredCheckboxChange = (event) => {
    const { value } = event.target;
    if (servicesRequired.includes(value)) {
      // If the checkbox is checked, add the value to the array
      setServicesRequired(servicesRequired.filter((d) => d !== value));
    } else {
      setServicesRequired([...servicesRequired, value]);
    }
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
  };

  const handleIntendToExtractTeethCheckboxChange = (event) => {
    setIntendToExtractTeeth(event.target.value);
  };

  const handleAnyTeethMobileCheckboxChange = (event) => {
    setAnyTeethMobile(event.target.value);
  };

  const handleGuideCheckboxChange = (event) => {
    setGuideTypeValue(event.target.value);
  };

  const validateSurgeryDate = (value) => {
    return value ? true : false;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additonalInstructions",
      "hasMissingTeeth",
      "missingTeethSelectedTeeth",
      "intendToExtractTeeth",
      "guideType",
      "mobileTeeth",
      "preferredImplantCompany",
      "implantLine",
      "servicesRequired",
      "abutmentPreferences",
      "guidanceLevel",
      "flapBeRaised",
      "densahBursUsed",
      "densahBurGuideRequired",
    ];

    return requiredFields.includes(fieldName);
  };

  const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const handleSurgicalKitCheckboxChange = (event) => {
    setImplantSurgicalKit(event.target.value);
  };
  const handleDensahBursUsedCheckboxChange = (event) => {
    setDensahBursUsed(event.target.value);
  };
  const handleDensahBurGuideRequiredCheckboxChange = (event) => {
    setDensahBurGuideRequired(event.target.value);
  };
  const handleFlapBeRaisedCheckboxChange = (event) => {
    setFlapBeRaised(event.target.value);
  };
  const handleBoneReductionNecessaryCheckboxChange = (event) => {
    setBoneReductionNecessary(event.target.value);
  };
  const handleConversionDentureAvailableCheckboxChange = (event) => {
    setConversionDentureAvailable(event.target.value);
  };
  const handleDigitalConversionDentureCheckboxChange = (event) => {
    setDigitalConversionDenture(event.target.value);
  };
  const handleProsthesisBeUsedCheckboxChange = (event) => {
    setProsthesisBeUsed(event.target.value);
  };

  const validateTextField = (value) => {
    return value.trim().length > 0;
  };

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(guideTypeTeethSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...guideTypeTeethSelected[d]);
      });
    return restrictedTeethData;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, event.target);
    if (name === "additonalInstructions") setSpecialInstructions(value);
    if (name === "abutmentPreferences") setAbutmentPreferences(value);
    if (name === "implantLine") setImplantLine(value);
    if (name === "preferredImplantCompany") setPreferredImplantCompany(value);
    if (name === "surgeryDate") setSurgeryDate(value);
  };
  const submitToothMovementForm = async () => {
    // eslint-disable-next-line no-unused-vars
    // if (toothData?.length > 0) {
    // const overBiteSelectOptionsObj = {
    //   overBiteCorrtOptimize: false,
    //   overBiteCorrtMaintain: false,
    //   overBiteCorrtImprove1mm: false,
    //   overBiteCorrtImprove2mm: false,
    //   overBiteCorrtImprove3mm: false,
    //   overBiteCorrtImprove4mm: false,
    // };
    // const overjetSelectOptionsObj = {
    //   overJetCorrtOptimize: false,
    //   overJetCorrtMaintain: false,
    //   overJetCorrtImprove1mm: false,
    //   overJetCorrtImprove2mm: false,
    //   overJetCorrtImprove3mm: false,
    //   overJetCorrtImprove4mm: false,
    // };
    // for (const key of toothData) {
    //   if (overBiteSelectOptionsObj.hasOwnProperty(key)) {
    //     overBiteSelectOptionsObj[key] = true;
    //   }
    // }
    // for (const key of toothData) {
    //   if (overjetSelectOptionsObj.hasOwnProperty(key)) {
    //     overjetSelectOptionsObj[key] = true;
    //   }
    // }
    // const isUpdated = arraysHaveSameValues(initialToothData, toothData);
    // const data = {
    //   //teeth: selectedMissingTeeth,
    //   iPRValue:
    //     iPRValue === "iprPosterior"
    //       ? { iprPosterior: true, iprAnterior: false }
    //       : { iprPosterior: false, iprAnterior: true },
    //   overjetSelectOptions: overjetSelectOptionsObj,
    //   overBiteSelectOptions: overBiteSelectOptionsObj,
    //   crossBiteValue:
    //     crossBiteValue === "crossBiteCorrtMaintain"
    //       ? { crossBiteCorrtMaintain: true, crossBiteCorrtOptimize: false }
    //       : { crossBiteCorrtMaintain: false, crossBiteCorrtOptimize: true },
    //   additonalInstructions: additonalInstructions,
    // };
    // const accessToken = await getAccessTokenSilently({
    //   authorizationParams: {
    //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
    //   },
    // });
    // if (orderByData?.ordertoothmovement?.length === 0) {
    //   const resp = await saveOrderToothMovData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     toothMovementData: data,
    //   });
    //   const respTeeth = await saveOrderTeethData({
    //     accessToken,
    //     orderObjId: orderByData?._id,
    //     teeth: selectedMissingTeeth,
    //   });
    //   setOrderUpdated(true);
    //   setActiveTab("Upload Files");
    // } else {
    //   if (!isUpdated) {
    //     const resp = await updateOrderToothMovData({
    //       accessToken,
    //       _id: orderByData?.ordertoothmovement[0]?._id,
    //       toothMovementData: data,
    //     });
    //     if (resp?.data?.type === 1) {
    //       GetToast("Success", "");
    //       setOrderUpdated(true);
    //       setActiveTab("Upload Files");
    //     } else {
    //       GetToast("Error", "error");
    //     }
    //   } else {
    //     setActiveTab("Upload Files");
    //   }
    // }
    // // }

    submitOrderData({
      missingTeeth: {
        hasMissingTeeth: hasMissingTeeth ? hasMissingTeeth === "Yes" : null,
        teeth: missingTeethSelectedTeeth,
      },
      teethToExtract: {
        teethToExtract: intendToExtractTeeth ? intendToExtractTeeth === "Yes" : null,
        teeth: teethExtractionSelectedTeeth,
      },
      anyTeethMobile: {
        anyTeethMobile: anyTeethMobile ? anyTeethMobile === "Yes" : null,
        teeth: mobileTeeth,
      },
      services: Object.keys(guideTypeTeethSelected)?.map((item) => ({
        serviceObjId: item,
        teeth: guideTypeTeethSelected[item],
      })),
      guideType: {
        option: guideTypeValue,
        incorporateBoneReduction: boneReductionNecessary ? boneReductionNecessary === "Yes" : null,
        additionalStackableOptions: stackableGuideOptions,
        conversionDentureAvailable: conversionDentureAvailable
          ? conversionDentureAvailable === "Yes"
          : null,
        digitalConversionDentureRequired: digitalConversionDenture
          ? digitalConversionDenture === "Yes"
          : null,
        prosthesisBeUsed: prosthesisBeUsed ? prosthesisBeUsed === "Yes" : null,
        abutmentPreferences: abutmentPreferences,
      },
      surgeryDate: surgeryDate?.toLocaleDateString() ?? "",
      preferredImplantCompany: preferredImplantCompany,
      implantLine: implantLine,
      implantSurgicalKit: {
        option: implantSurgicalKit,
        densahBursUsed: densahBursUsed ? densahBursUsed === "Yes" : null,
        densahBurGuideDesired: densahBurGuideRequired ? densahBurGuideRequired === "Yes" : null,
        flapBeRaised: flapBeRaised ? flapBeRaised === "Yes" : null,
      },
      guidanceLevel: guidanceLevel,
      servicesRequired: servicesRequired,
      additionalInstructions: additonalInstructions,
    });
    setActiveTab("Upload Files");
  };

  const prepareServicesElements = () => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              guideTypeTeethSelected.hasOwnProperty(eachService?.serviceObjId) &&
              guideTypeTeethSelected[eachService?.serviceObjId]?.length > 0
            }
            handleSelectedIcon={() => {
              console.log("guideTypeTeethSelected", guideTypeTeethSelected);
              setGuideTypeTeethSelected((prev) => {
                const copy = JSON.parse(JSON.stringify(prev));
                delete copy[eachService?.serviceObjId];
                console.log("eachService?.serviceObjId", eachService?.serviceObjId);
                console.log("copy", copy);
                console.log("prev", prev);
                return copy;
              });
            }}
            id={serviceObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  return (
    <div className="">
      <div className="w-10/12">
        <div className="text-white flex flex-col">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth{" "}
            {isFieldRequired("hasMissingTeeth") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mx-5 mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") ? (
                  <span className="text-red-500">*</span>
                ) : (
                  ""
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={setMissingTeethSelectedTeeth}
                defaultNotation={"FDI"}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <div className="text-white flex my-8">
          Do you intend to extract any teeth?{" "}
          {isFieldRequired("intendToExtractTeeth") ? <span className="text-red-500">*</span> : ""}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              checked={intendToExtractTeeth === "Yes"}
              onChange={handleIntendToExtractTeethCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              checked={intendToExtractTeeth === "No"}
              onChange={handleIntendToExtractTeethCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        {intendToExtractTeeth === "Yes" && (
          <div className="mx-5">
            <p className="text-white text-[17px] mb-2">
              Please select any teeth that you intend to extract{" "}
              {isFieldRequired("teethExtractionSelectedTeeth") ? (
                <span className="text-red-500">*</span>
              ) : (
                ""
              )}
            </p>
            <div className="">
              <TeethV2
                allTeethData={[]}
                selectedTeeth={teethExtractionSelectedTeeth}
                setSelectedTeeth={setTeethExtractionSelectedTeeth}
                defaultNotation={"FDI"}
                notationVisible={false}
                missingTeeth={missingTeethSelectedTeeth}
              />
            </div>
          </div>
        )}
        <div>
          <div className="text-white flex mt-6 mb-8">
            Are any of the remaining teeth mobile?
            {isFieldRequired("anyTeethMobile") ? <span className="text-red-500">*</span> : ""}
            <div className="flex items-center mx-3">
              <CircularCheckbox
                value="Yes"
                checked={anyTeethMobile === "Yes"}
                onChange={handleAnyTeethMobileCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Yes</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={anyTeethMobile === "No"}
                onChange={handleAnyTeethMobileCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">No</p>
            </div>
          </div>
          {anyTeethMobile === "Yes" && (
            <div className="mx-5">
              <p className="text-white text-[16px] mb-2">
                Please select remaining mobile teeth{" "}
                {isFieldRequired("mobileTeeth") ? <span className="text-red-500">*</span> : ""}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={mobileTeeth}
                setSelectedTeeth={setMobileTeeth}
                defaultNotation={"FDI"}
                notationVisible={false}
                missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
              />
            </div>
          )}
        </div>
        <div className="mb-3 mt-6">
          <p className="font-medium text-[24px] text-white mb-4">Case Type</p>
          <p className="text-[16px] text-[#777] my-3">
            Please click an implant type below and choose the teeth that apply for that restorative
            type in the chart that appears. Multiple implant types can be selected and multiple
            teeth can be selected on each implant type's chart. If you prefer not to use our
            charting system, you can also provide written instructions in the Additional
            Instructions column below.
          </p>
          <p className="text-[17px] text-[#777] mb-8">
            Please choose your case type correctly and then choose the type of surgical guide
            service you require.
          </p>
          {selectedServiceCategory?._id && Object.keys(guideTypeTeethSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={8}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements()}
                    numberOfCardsShownInSingleSlide={8}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements()}
                    numberOfCardsShownInSingleSlide={8}
                  />
                </div>
              </div>
              {selectedService?.serviceName &&
                [
                  "Single or multiple single implant surgical guide",
                  "Implant bridge surgical guide",
                  "Overdenture (soft tissue) surgical guide for Locators or Bar",
                  "Soft tissue based surgical guide for All on X",
                  "Full arch stackable surgical guides for All on X (Resin or Metal)",
                ].includes(selectedService?.serviceName) && (
                  <div>
                    <p className="font-medium text-[24px] text-white mt-4 mb-2">
                      Guide Type{" "}
                      {isFieldRequired("guideType") ? <span className="text-red-500">*</span> : ""}
                    </p>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-x-8">
                      {[
                        "Single or multiple single implant surgical guide",
                        "Implant bridge surgical guide",
                      ].includes(selectedService?.serviceName) && (
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Tooth Supported Guide"
                            checked={guideTypeValue === "Tooth Supported Guide"}
                            onChange={handleGuideCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Tooth Supported Guide</p>
                        </div>
                      )}
                      {[
                        "Implant bridge surgical guide",
                        "Overdenture (soft tissue) surgical guide for Locators or Bar",
                        "Soft tissue based surgical guide for All on X",
                      ].includes(selectedService?.serviceName) && (
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Mucosa Supported Guide"
                            checked={guideTypeValue === "Mucosa Supported Guide"}
                            onChange={handleGuideCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Mucosa Supported Guide</p>
                        </div>
                      )}
                      {[
                        "Overdenture (soft tissue) surgical guide for Locators or Bar",
                        "Soft tissue based surgical guide for All on X",
                      ].includes(selectedService?.serviceName) && (
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Bone Supported Guide"
                            checked={guideTypeValue === "Bone Supported Guide"}
                            onChange={handleGuideCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Bone Supported Guide</p>
                        </div>
                      )}
                      {[
                        "Full arch stackable surgical guides for All on X (Resin or Metal)",
                      ].includes(selectedService?.serviceName) && (
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="Stackable guides"
                            checked={guideTypeValue === "Stackable guides"}
                            onChange={handleGuideCheckboxChange}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            Stackable guides (Base guide, Pin guide, Drill guide)
                          </p>
                        </div>
                      )}
                    </div>
                    {(guideTypeValue === "Bone Supported Guide" ||
                      [
                        "Full arch stackable surgical guides for All on X (Resin or Metal)",
                      ].includes(selectedService?.serviceName)) && (
                      <div className="text-white mt-5">
                        <div className="flex">
                          If bone reduction is necessary, can this be incorporated into the plan?{" "}
                          <div className="flex items-center mx-3">
                            <CircularCheckbox
                              value="Yes"
                              checked={boneReductionNecessary === "Yes"}
                              onChange={handleBoneReductionNecessaryCheckboxChange}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <p className="text-white text-[16px]">Yes</p>
                          </div>
                          <div className="flex items-center">
                            <CircularCheckbox
                              value="No"
                              checked={boneReductionNecessary === "No"}
                              onChange={handleBoneReductionNecessaryCheckboxChange}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <p className="text-white text-[16px]">No</p>
                          </div>
                        </div>
                        <p className="text-[#777]">
                          {[
                            "Full arch stackable surgical guides for All on X (Resin or Metal)",
                          ].includes(selectedService?.serviceName)
                            ? "(If the Yes option is selected, a bone reduction guide will automatically be provided. The other guides will then be provided as stackable options on the bone reduction guide.)"
                            : "(If the Yes option is selected, a bone reduction guide will automatically be provided and charged for. The drill guide will then be provided as a stackable option on the bone reduction guide.)"}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              {selectedService?.serviceObjId && guideTypeTeethSelected && (
                <div className="mt-10 mb-7">
                  <p className="text-white text-[16px] mb-2">Please select implant site(s)</p>
                  <TeethV2
                    key={
                      selectedService?.serviceObjId +
                      guideTypeTeethSelected[selectedService?.serviceObjId]?.length
                    }
                    allTeethData={[]}
                    restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
                    restrictedTeethMessage={"This tooth is already selected in other case type"}
                    selectedTeeth={guideTypeTeethSelected[selectedService?.serviceObjId] || []}
                    setSelectedTeeth={(value) => {
                      let result = value(
                        guideTypeTeethSelected[selectedService?.serviceObjId] || []
                      );
                      setGuideTypeTeethSelected({
                        ...guideTypeTeethSelected,
                        [selectedService?.serviceObjId]: result,
                      });
                    }}
                    defaultNotation={"FDI"}
                    notationVisible={false}
                    missingTeeth={[...missingTeethSelectedTeeth, ...teethExtractionSelectedTeeth]}
                    missingTeethSelectable={true}
                    otherToothRestrictedMessage="Other tooth is not selectable"
                  />
                </div>
              )}
            </>
          )}
        </div>
        {[
          "Full arch stackable surgical guides for All on X (Resin or Metal)",
          "Soft tissue based surgical guide for All on X",
        ].includes(selectedService?.serviceName) && (
          <>
            <div>
              <p className="font-medium text-white mt-6 mb-2">
                What additional stackable guide options are desired? <small>(Optional)</small>
              </p>
              <div className="ml-6">
                <div className="grid md:grid-rows-3 grid-rows-1  gap-x-8 gap-y-2 text-[16px] text-white">
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Multi-unit abutment positioning guide"
                      checked={stackableGuideOptions.includes(
                        "Multi-unit abutment positioning guide"
                      )}
                      onChange={handleStackableGuideOptionsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Multi-unit abutment positioning guide</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="Guided temporary Prosthesis"
                      checked={stackableGuideOptions.includes("Guided temporary Prosthesis")}
                      onChange={handleStackableGuideOptionsCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Guided temporary Prosthesis</p>
                  </div>
                </div>
              </div>
            </div>
            {!stackableGuideOptions.includes("Guided temporary Prosthesis") && (
              <div className="text-white flex mb-6">
                If the guided temporary prosthesis option is not selected, is a conversion denture
                available?
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={conversionDentureAvailable === "Yes"}
                    onChange={handleConversionDentureAvailableCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={conversionDentureAvailable === "No"}
                    onChange={handleConversionDentureAvailableCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            )}
          </>
        )}
        {[
          "Full arch stackable surgical guides for All on X (Resin or Metal)",
          "Soft tissue based surgical guide for All on X",
        ].includes(selectedService?.serviceName) &&
          !stackableGuideOptions.includes("Guided temporary Prosthesis") &&
          conversionDentureAvailable === "No" && (
            <div className="mb-3">
              <div className="text-white flex mb-7">
                A digital conversion denture can also be provided. Is this required?
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={digitalConversionDenture === "Yes"}
                    onChange={handleDigitalConversionDentureCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={digitalConversionDenture === "No"}
                    onChange={handleDigitalConversionDentureCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
              <div className="text-white flex flex-col">
                <TextAreaInputField
                  labelColor={"#fff"}
                  labelText={"Please indicate abutment preferences"}
                  errorMessage={"This field is required"}
                  placeholder={"Enter here"}
                  isFieldRequired={isFieldRequired("abutmentPreferences")}
                  htmlFor={"abutmentPreferences"}
                  name={"abutmentPreferences"}
                  value={abutmentPreferences}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateTextField}
                  rows={4}
                  cols={5}
                />
              </div>
            </div>
          )}
        <div className=" flex md:flex-row flex-col gap-8">
          <DateInputFieldV2
            labelColor={"#777"}
            labelText={"Surgery Date"}
            errorMessage={""}
            placeholder={"DD-MM-YYYY"}
            isFieldRequired={isFieldRequired("surgeryDate")}
            htmlFor={"surgeryDate"}
            name={"surgeryDate"}
            value={surgeryDate}
            handleInputChange={setSurgeryDate}
            validationFunctionName={validateSurgeryDate}
          />
        </div>
        <div className="flex md:flex-row flex-col gap-8 mt-5">
          <div>
            <TextInputField
              labelColor={"#fff"}
              labelText={"Preferred Implant Company"}
              errorMessage={""}
              placeholder={"Enter here"}
              isFieldRequired={isFieldRequired("preferredImplantCompany")}
              htmlFor={"preferredImplantCompany"}
              name={"preferredImplantCompany"}
              value={preferredImplantCompany}
              handleInputChange={handleInputChange}
              validationFunctionName={validateTextField}
              margin={"0px"}
            />
          </div>
          <div>
            <TextInputField
              labelColor={"#fff"}
              labelText={"Implant Line"}
              errorMessage={""}
              placeholder={"Enter here"}
              isFieldRequired={isFieldRequired("implantLine")}
              htmlFor={"implantLine"}
              name={"implantLine"}
              value={implantLine}
              handleInputChange={handleInputChange}
              validationFunctionName={validateTextField}
              margin={"0px"}
            />
          </div>
        </div>
        <div className="my-4">
          <p className="font-medium text-[24px] text-white mb-4">
            Implant Surgical Kit{" "}
            {isFieldRequired("implantSurgicalKit") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div className="grid md:grid-cols-3 md:grid-rows-1 grid-cols-1 grid-rows-4 gap-y-4 gap-x-8">
            <div className="flex items-center">
              <CircularCheckbox
                value="Guided Kit"
                checked={implantSurgicalKit === "Guided Kit"}
                onChange={handleSurgicalKitCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Guided Kit</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="Regular Kit"
                checked={implantSurgicalKit === "Regular Kit"}
                onChange={handleSurgicalKitCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Regular Kit</p>
            </div>
          </div>
        </div>
        {implantSurgicalKit === "Guided Kit" && (
          <div className="ml-6 mb-3">
            <div className="text-white flex mb-2">
              ● Will Densah burs be used?{" "}
              {isFieldRequired("densahBursUsed") ? <span className="text-red-500">*</span> : ""}
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={densahBursUsed === "Yes"}
                  onChange={handleDensahBursUsedCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={densahBursUsed === "No"}
                  onChange={handleDensahBursUsedCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            <div className="text-white flex mb-2">
              ● Is a Densah bur guide desired?{" "}
              {isFieldRequired("densahBurGuideRequired") ? (
                <span className="text-red-500">*</span>
              ) : (
                ""
              )}
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={densahBurGuideRequired === "Yes"}
                  onChange={handleDensahBurGuideRequiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={densahBurGuideRequired === "No"}
                  onChange={handleDensahBurGuideRequiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            {!["Full arch stackable surgical guides for All on X (Resin or Metal)"].includes(
              selectedService?.serviceName
            ) && (
              <div className="text-white flex mb-2">
                ● Will a flap be raised?{" "}
                {isFieldRequired("flapBeRaised") ? <span className="text-red-500">*</span> : ""}
                <div className="flex items-center mx-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={flapBeRaised === "Yes"}
                    onChange={handleFlapBeRaisedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={flapBeRaised === "No"}
                    onChange={handleFlapBeRaisedCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mb-4">
          <p className="font-medium text-[24px] text-white mt-6 mb-4">
            Choose Level of Guidance Desired{" "}
            {isFieldRequired("guidanceLevel") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div>
            <div className="grid md:grid-rows-3 grid-rows-1  gap-x-8 gap-y-4 text-[16px] text-white">
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Pilot drill guide"
                  checked={guidanceLevel === "Pilot drill guide"}
                  onChange={handleGuidanceLevelCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Pilot drill guide</p>
              </div>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Full drill guide"
                  checked={guidanceLevel === "Full drill guide"}
                  onChange={handleGuidanceLevelCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Full drill guide</p>
              </div>
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Fully guided with Implant placement"
                  checked={guidanceLevel === "Fully guided with Implant placement"}
                  onChange={handleGuidanceLevelCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Fully guided with Implant placement</p>
              </div>
            </div>
            {!(guidanceLevel.length > 0) ? (
              <p className="text-sm text-red-500">Select at least one</p>
            ) : (
              <></>
            )}
          </div>

          {guidanceLevel.includes("Fully guided with Implant placement") &&
            guideTypeTeethSelected["65bfcd82a03607278060f727"]?.length > 0 && (
              <div className="ml-6 my-3">
                <div className="text-white flex">
                  ● Will the prosthesis be used as a morphological template for the guide?
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={prosthesisBeUsed === "Yes"}
                      onChange={handleProsthesisBeUsedCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={prosthesisBeUsed === "No"}
                      onChange={handleProsthesisBeUsedCheckboxChange}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="mb-8">
          <p className="font-medium text-[24px] text-white mt-6 mb-4">
            Choose Services Required{" "}
            {isFieldRequired("servicesRequired") ? <span className="text-red-500">*</span> : ""}
          </p>
          <div>
            <div className="grid md:grid-cols-4 md:grid-rows-1 grid-cols-1  gap-x-8 gap-y-4 text-[16px] text-white">
              <div className="flex items-center">
                <CircularCheckbox
                  value="Implant treatment planning only"
                  checked={servicesRequired.includes("Implant treatment planning only")}
                  onChange={handleservicesRequiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Implant treatment planning only</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="Surgical guide fabrication"
                  checked={servicesRequired.includes("Surgical guide fabrication")}
                  onChange={handleservicesRequiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Surgical guide fabrication</p>
              </div>
              {![
                "Full arch stackable surgical guides for All on X (Resin or Metal)",
                "Soft tissue based surgical guide for All on X",
              ].includes(selectedService?.serviceName) && (
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Abutment planning"
                    checked={servicesRequired.includes("Abutment planning")}
                    onChange={handleservicesRequiredCheckboxChange}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Abutment planning</p>
                </div>
              )}
              <div className="flex items-center">
                <CircularCheckbox
                  value="Jaw model for printing"
                  checked={servicesRequired.includes("Jaw model for printing")}
                  onChange={handleservicesRequiredCheckboxChange}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Jaw model for printing</p>
              </div>
            </div>
            {!(servicesRequired.length > 0) ? (
              <p className="text-sm text-red-500">Select at least one</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <TextAreaInputField
            labelColor={"#777"}
            labelText={"Additional Instructions"}
            errorMessage={"This field is required"}
            placeholder={"Enter Special Instructions"}
            isFieldRequired={isFieldRequired("additonalInstructions")}
            htmlFor={"additonalInstructions"}
            name={"additonalInstructions"}
            value={additonalInstructions}
            handleInputChange={handleInputChange}
            // validationFunctionName={validatePatientConcern}
            // isSubmitted={isPatientInitialFormSubmitted}
            rows={4}
            cols={5}
          />
          {additonalInstructions?.trim()?.length > 0 ? (
            <></>
          ) : (
            <p className="text-sm text-red-500">This field is required</p>
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Patient Details");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitToothMovementForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
