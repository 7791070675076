import React from "react";
import priceSlip from "../../Assets/images/priceSlip.svg";
function EstimatedServicePrice({ priceFrom, priceTo }) {
  return (
    <div className="ESTIMATED-SERVICE-PRICE">
      <p className="font-medium text-[20px] text-white mb-2">
        Estimated Service Price
      </p>
      <div className="relative flex items-center object-contain ">
        <img className="absolute top-0 left-0 h-full" src={priceSlip} alt="" />

        <div className="p-4">
          <p className="font-medium text-[15px] text-[#777]">From</p>
          <p className="font-bold text-2xl text-primary ">
            {priceFrom}{" "}
            <span className="text-base font-normal text-[#555] ">USD</span>{" "}
          </p>
        </div>
        <div className="h-1 w-5 bg-black"></div>
        <div className="p-4">
          <p className="font-medium text-[15px] text-[#777]">To</p>
          <p className="font-bold text-2xl text-primary">
            {priceTo}{" "}
            <span className="text-base font-normal text-[#555]">USD</span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EstimatedServicePrice;
