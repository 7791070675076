import star from "../../Assets/images/star.svg";
import React, { useEffect, useState } from "react";

export const CircularCheckbox = ({
  label,
  value,
  name,
  checked,
  onChange,
  borderColor,
  backgroundColor,
}) => {
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        className="hidden"
        value={value}
        name={name ?? ""}
        checked={checked}
        onChange={onChange}
      />
      <span
        style={{ borderColor: borderColor }}
        className="w-5 h-5 rounded-full border-2  flex items-center justify-center transition duration-300 ease-in-out !ml-0"
      >
        {checked && (
          <span
            style={{ backgroundColor: backgroundColor }}
            className="w-2 h-2 rounded-full "
          ></span>
        )}
      </span>
      <span className="text-white">{label}</span>
    </label>
  );
};

const CheckboxForm = ({ setModalIsOpen, data, userId, updateCategories, initialValue }) => {
  const [selectedValue, setSelectedValue] = useState("Basic");

  console.log("selectedValue", selectedValue)
  console.log("initialValue", initialValue)

  const handleCheckboxChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateCategories(selectedValue, userId);
    setModalIsOpen((preState) => !preState);
  };

  useEffect(()=>{
    if(initialValue){
      setSelectedValue(initialValue);
    }
    return ()=>{setSelectedValue("")}
  },[initialValue])

  return (
    <form className="h-full flex flex-col" onSubmit={handleSubmit}>
      <div className="flex-1 mt-4 mb-4">
        {
          data?.map((item) => <div key={item._id} className="flex items-center justify-between border-b border-gray-500 border-opacity-20 py-6">
            <div className="flex items-center gap-4">
              <img style={{ height: '30px', width: '30px' }} src={item.image} alt="" />
              <p className="text-[16px] text-white">{item.categoryName}</p>
            </div>
            <CircularCheckbox
              value={item._id}
              checked={selectedValue === item._id}
              onChange={handleCheckboxChange}
              borderColor={"#53C28C"}
              backgroundColor={"#53C28C"}
            />
          </div>)
        }

        {/* <div className="flex items-center justify-between border-b border-gray-500 border-opacity-20 py-6">
          <div className="flex items-center gap-4">
            <img src={star} alt="" />
            <p className="text-[16px] text-[#555]">Intermediate</p>
          </div>

          <CircularCheckbox
            value="Intermediate"
            checked={selectedValue === "Intermediate"}
            onChange={handleCheckboxChange}
            borderColor={"#53C28C"}
            backgroundColor={"#53C28C"}
          />
        </div>
        <div className="flex items-center justify-between border-b border-gray-500 border-opacity-20 py-6">
          <div className="flex items-center gap-4">
            <img src={star} alt="" />
            <p className="text-[16px] text-[#555]">Advanced</p>
          </div>

          <CircularCheckbox
            value="Advanced"
            checked={selectedValue === "Advanced"}
            onChange={handleCheckboxChange}
            borderColor={"#53C28C"}
            backgroundColor={"#53C28C"}
          />
        </div> */}
      </div>
      <div className="flex items-center justify-end gap-4">
        <button
          onClick={() => setModalIsOpen(false)}
          className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default CheckboxForm;
