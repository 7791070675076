import React, { useState } from "react";

function CustomTabsProfile({ outputObject, tabNamesArray }) {
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="h-full">
      <div className="Tabs h-full">
        {/* Tab nav */}
        <ul className="NAV  flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-[#222026] border-opacity-10 py-4">
          {tabNamesArray.map((title, i) => {
            return (
              <li
                key={i + 1}
                className="relative"
                onClick={() => handleTabSuper(title)}
              >
                <div
                  className={
                    activeTab === title
                      ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-primary before:left-0 before:-bottom-4"
                      : "hover:text-primary cursor-pointer text-[#999] font-normal"
                  }
                >
                  {title}
                </div>
              </li>
            );
          })}
        </ul>

        <div className="outlet md:h-fit h-[50vh] overflow-visible ">
          {Object.keys(outputObject).map((key) => {
            if (tabNamesArray.includes(key)) {
              return <>{activeTab === key ? <>{outputObject[key]}</> : null}</>;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

export default CustomTabsProfile;
