// table dummy data

import { useState, useEffect } from "react";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward } from "react-icons/io";
import bell from "../../Assets/images/bellIcon.svg";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import circle from "../../Assets/images/circle.png";
import CustomTabsProfile from "../Profile-Comps/CustomTabsProfile";
import TabComponentProfile from "../Profile-Comps/TabComponentProfile";
import DetailTile from "../Profile-Comps/DetailTile";
import profileIcon from "../../Assets/images/profileIcon.svg";
import { useReadDentistsMutation } from "../../services/user";
import { useAuth0 } from "@auth0/auth0-react";
import greenTick from "../../Assets/images/greenTick.svg";
import { LoginButton } from "../../Screens/Login/Login";
import { setDentistData } from "../../reducers/UserManagementReducer";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../Notification/Notification";

export const DentistTab = () => {
  const dispatch = useDispatch();
  const { dentistData } = useSelector((state) => state.userManagementDetails);
  const { user, isAuthenticated } = useAuth0();
  const [selectedButton, setSelectedButton] = useState(null);
  const [currentDentistDetails, setcurrentDentistDetails] = useState({});
  const [dentistDt, setDentistDt] = useState(dentistData);
  const { getAccessTokenSilently } = useAuth0();
  const [readDentists] = useReadDentistsMutation();
  const personalDetails = useSelector((state)=> state.personalDetails);

  const getDentistData = async (accessToken) => {
    const resp = await readDentists(accessToken).unwrap();
    if (resp?.data?.length > 0) {
      // debugger;
      dispatch(setDentistData(resp?.data));
      setDentistDt(resp?.data);
    }
  };

  const readUsersInfo = async () => {
    // debugger;
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getDentistData(accessToken);
  };

  useEffect(() => {
    if (!dentistData.length > 0) {
      readUsersInfo();
    }
  }, []);

  // data for table here  headers and data
  const headersDentist = [
    { headerId: "name", headerName: "Name", headerSort: true },
    { headerId: "crowdHireId", headerName: "Digital Dentition ID", headerSort: true },
    {
      headerId: "regAuthority",
      headerName: "Reg Authority",
      headerSort: false,
    },
    { headerId: "regNumber", headerName: "Reg No", headerSort: true },
    {
      headerId: "country",
      headerName: "Country",
      headerSort: true,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
  ];

  console.log(dentistDt, "dentistDt")
  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  const prepareDentistsData = () => {
    if (dentistDt?.length) {
      const arrayOfData = [];
      // debugger;

      // Create HTML elements based on servicesData
      dentistDt.map((eachService) =>
        arrayOfData.push({
          image: eachService?.picture,
          crowdHireId: eachService?.details?.crowdHireId || "Not Available",
          name:
            eachService?.details?.firstName +
            " " +
            eachService?.details?.lastName || "Not Available",
          firstName: eachService?.details?.firstName || "Not Available",
          lastName: eachService?.details?.lastName || "Not Available",
          mobile: eachService?.details?.mobile || "Not Available",
          email: eachService?.details?.email || "Not Available",
          regAuthority:
            eachService?.details?.clinicalDetails?.[0]?.regAuthority ||
            "Not Available",
          regName:
            eachService?.details?.clinicalDetails?.[0]?.regName ||
            "Not Available",
          regNumber:
            eachService?.details?.clinicalDetails?.[0]?.regNumber ||
            "Not Available",
          country:
            eachService?.details?.userAddressDetails?.[0]?.country ||
            "Not Available",
          address:
            eachService?.details?.userAddressDetails?.[0]?.address ||
            "Not Available",
          pincode:
            eachService?.details?.userAddressDetails?.[0]?.pincode ||
            "Not Available",
        })
      );

      console.log(arrayOfData, "arrayOfData")
      return arrayOfData;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  // ends here

  // this is for when user clicks on actions button in table
  // dentist details
  const detailTileListOne = [
    <DetailTile
      heading={"First Name"}
      subHeading={currentDentistDetails?.firstName}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Last Name"}
      subHeading={currentDentistDetails?.lastName}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Mobile"}
      subHeading={currentDentistDetails?.mobile}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Email"}
      subHeading={currentDentistDetails?.email}
      icon={profileIcon}
    />,
  ];
  const detailTileListTwo = [
    <DetailTile
      heading={"Address"}
      subHeading={currentDentistDetails?.address}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Country"}
      subHeading={currentDentistDetails?.country}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Pincode"}
      subHeading={currentDentistDetails?.pincode}
      icon={profileIcon}
    />,
  ];

  const detailTileListThree = [
    <DetailTile
      heading={"Registration Number"}
      subHeading={currentDentistDetails?.regNumber}
      icon={profileIcon}
    />,
    <DetailTile
      heading={"Registration Authority"}
      subHeading={currentDentistDetails?.regAuthority}
      icon={profileIcon}
    />,
  ];
  // this is wrapper for the data of the above
  const tileWrapperOne = (
    <TabComponentProfile detailTileList={detailTileListOne} />
  );
  const tileWrapperTwo = (
    <TabComponentProfile detailTileList={detailTileListTwo} />
  );
  const tileWrapperThree = (
    <TabComponentProfile detailTileList={detailTileListThree} />
  );
  // these are tab names inside of the tab
  const tabNamesArrayDentist = ["Personal Details", "Address Details", "Clinic Details"];

  const tileObjectDentist = {
    "Personal Details": tileWrapperOne,
    "Address Details": tileWrapperTwo,
    "Clinic Details": tileWrapperThree
  };
  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            User Management <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary">
              {" "}
              Dentists
            </span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
            CH00001
            <img
              className="relative top-[4px] right-0"
              src={greenTick}
              alt=""
            />
          </button>
          <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <div className="HEADING flex md:justify-between items-center gap-4 mb-4 mt-2">
        <p className=" font-medium text-[34px] leading-7 md:mr-8 text-white">Dentists</p>
      </div>
      {selectedButton === null && (
        <div className="bg-secondary p-4 rounded-md md:h-[80vh] h-[70vh]">
          <DynamicTable
            setSelectedButton={setSelectedButton}
            headers={headersDentist}
            data={prepareDentistsData()}
            includeImgVideoButton={true}
            categoryTable={false}
            serviceProviderTable={false}
            dentistTable={true}
            handleButtonClickForUM={(row) => {
              // debugger;
              setcurrentDentistDetails(row);
              setSelectedButton(true);
            }}
          />
        </div>
      )}
      {selectedButton && (
        <div className="WHITE-SECTION bg-secondary md:p-8 p-4 md:h-[70vh] overflow-auto">
          <div className="flex flex-col gap-4 md:gap-0 md:flex-row md:items-center justify-between mb-4">
            <p className="text-white font-semibold text-xl">
              Dentist Details
            </p>
            {/* <div className="flex items-center justify-center gap-4 ">
                <p>Basic</p>
                <div className="w-[1px] h-[20px] bg-black opacity-20"></div>
                <ToggleButton />
                <img src={eye} alt="eye" onClick={() => {}} />
                <img src={note} alt="note" onClick={() => {}} />
              </div> */}
          </div>
          <div className="CUSTOM-TAB">
            <CustomTabsProfile
              tabNamesArray={tabNamesArrayDentist}
              outputObject={tileObjectDentist}
            />
          </div>
        </div>
      )}
    </>
  );
};
