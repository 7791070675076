import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GetToast } from "../Components/Toast-Comps/GetToast";
const UseAccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
            },
          });
          setAccessToken(token);
        } catch (error) {
          GetToast(error, "error");
          console.error("Error fetching access token:", error);
        }
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return accessToken;
};

export default UseAccessToken;
