import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allService: [],
    userService: [],
};

export const spServiceDetailsSlice = createSlice({
    name: 'spServiceDetails',
    initialState,
    reducers: {
        setAllServiceDetails: (state, action) => {
            state.allService = action.payload;
        },
        setUserServiceDetails: (state, action) => {
            state.userService = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setAllServiceDetails,
    setUserServiceDetails
} = spServiceDetailsSlice.actions