import React, { useState, useEffect } from "react";
import classNames from "classnames";

const ActiveToggleButton = ({ currentState, setState, className }) => {
  const toggleButtonClasses = classNames(
    "relative inline-block w-[36px] h-[20px] rounded-full transition duration-300 ease-in-out",
    {
      "bg-gray-300": !currentState,
      "bg-[#FF8503]": currentState,
    }
  );

  const handleToggle = () => {
    setState(!currentState);
  };

  return (
    <button className={`${toggleButtonClasses} ${className ?? ""}`}
      onClick={() => {
        handleToggle();
      }}>
      <span
        className={classNames(
          "inline-block relative -left-[8px] w-5 h-5 rounded-full bg-white shadow transform transition duration-300 ease-in-out",
          {
            "translate-x-4": currentState,
            "translate-x-0": !currentState,
          }
        )}
      ></span>
    </button>
  );
};

export default ActiveToggleButton;
