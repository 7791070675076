import React, { useEffect, useState } from "react";

const DropdownField = ({
  labelText,
  labelColor,
  labelClass,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validationFunctionName,
  options,
}) => {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);
  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className={`text-white ${labelClass}`}
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        onInvalid={(e) => {
          e.preventDefault();
          setIfInvalid(true);
          e.target.setCustomValidity("");
        }}
        onInput={(e) => {
          if (
            isSubmitted &&
            !validationFunctionName(value) &&
            isFieldRequired
          ) {
            setIfInvalid(false);
          }
        }}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
          (isSubmitted && !validationFunctionName(value) && isFieldRequired) ||
          ifInvalid
            ? "border-red-500"
            : ""
        }`}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options &&
          options?.length &&
          options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default DropdownField;
