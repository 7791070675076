import * as React from "react"
export const Teeth31 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={23} height={85} fill="none">
            <path
                fill="#EFD1D0"
                d="M2.879 27.52c1.217.243 2.13 1.46 2.982 2.739l.061.121c.73 1.218 1.583 2.557 2.983 3.226.487.183.973.304 1.521.304 1.096 0 2.252-.304 3.409-1.034.913-.609 1.765-1.4 2.556-2.191a17.1 17.1 0 0 1 1.096-1.035c.73-.67 1.4-1.096 2.07-1.461.912-.426 1.46-.609 1.703-.548l-.121 1.096c-.061.182-.122.426-.183.609v.06c-.06.244-.122.487-.183.609-4.017 15.947-5.964 32.928-5.903 51.919-.61 1.095-1.218 1.643-1.826 1.704-.73 0-1.461-.609-2.313-1.887-1.826-4.382-3.105-9.434-3.652-15.034-.244-2.252-.244-4.565-.244-6.756 0-1.826 0-3.713-.182-5.6-.67-8.947-1.887-17.894-3.713-26.659-.061-.122-.061-.122-.061-.183Z"
            />
            <path
                fill="#fff"
                fillOpacity={0.8}
                d="M14.687 1.956c.304.06.609.06.913.121 1.339.183 2.921.366 3.895 1.035.852.609 1.035 1.644 1.157 2.678 0 .183.06.365.06.487 1.34 8.217 1.522 14.851.61 20.573-.366-.061-.974.06-2.13.609-.731.365-1.4.852-2.192 1.582-.365.365-.73.73-1.157 1.035-.73.73-1.521 1.521-2.434 2.07-1.461.973-3.043 1.217-4.26.669C7.93 32.267 7.2 31.05 6.47 29.893l-.06-.122c-.67-1.034-1.827-2.982-3.714-3.104-.121-.487-.304-.974-.426-1.4-.243-.852-.547-1.643-.67-2.495-.243-1.704-.364-3.409-.425-5.052-.122-3.774-.061-7.547.06-11.26l.061-1.522c.122-2.678 1.826-3.043 4.687-3.347 2.86-.122 5.782-.061 8.704.365Z"
                opacity={0.8}
            />
            <path
                fill="#000"
                fillOpacity={0.4}
                d="M5.8 30.319c-.79-1.34-1.764-2.556-2.982-2.739v.06c1.826 8.766 3.105 17.713 3.713 26.66.122 1.887.122 3.774.183 5.6 0 2.191.06 4.504.243 6.756.548 5.6 1.826 10.651 3.652 15.034.852 1.278 1.644 1.948 2.313 1.887.609 0 1.217-.609 1.826-1.705-.06-18.99 1.887-35.971 5.904-51.918 0-.122.061-.365.183-.609v-.06c.06-.183.121-.427.182-.61l.122-1.095c-.243 0-.791.122-1.704.548-.67.304-1.34.791-2.07 1.46-.365.305-.73.67-1.095 1.035-.791.73-1.583 1.522-2.556 2.192-1.096.73-2.313 1.095-3.409 1.034-.548 0-1.035-.121-1.522-.304-1.4-.609-2.252-2.009-2.982-3.226Zm-3.53-4.87c.122.488.305.914.427 1.4 1.887.122 3.043 2.07 3.713 3.105l.06.122c.73 1.156 1.461 2.434 2.679 2.921 1.217.548 2.8.304 4.26-.67.913-.608 1.704-1.338 2.435-2.069.365-.365.73-.73 1.156-1.035a10.796 10.796 0 0 1 2.191-1.582c1.157-.548 1.765-.67 2.13-.609.975-5.782.792-12.356-.608-20.572 0-.183-.06-.305-.06-.487-.122-1.096-.305-2.07-1.157-2.678-.913-.67-2.557-.913-3.896-1.035-.304-.061-.608-.061-.913-.122-2.921-.487-5.843-.548-8.764-.243-2.8.304-4.565.67-4.687 3.347l-.06 1.522c-.062 3.713-.184 7.486-.062 11.26.061 1.583.122 3.348.426 5.052.183.67.426 1.522.73 2.374ZM.567 6.582.627 5c.122-3.47 2.74-3.713 5.235-3.957 2.982-.304 5.965-.243 8.947.244.304.06.548.06.913.122 1.4.182 3.104.426 4.2 1.156 1.095.791 1.278 2.07 1.46 3.165 0 .183.061.304.061.487 1.34 8.339 1.522 15.034.548 20.877-.06.183-.06.426-.06.609 0 .426-.062.913-.305 1.765-4.139 16.069-6.087 33.293-6.026 52.466v.061l-.06.061c-.731 1.4-1.522 2.07-2.435 2.13h-.122c-.913 0-1.826-.73-2.8-2.252-1.887-4.504-3.165-9.617-3.713-15.277-.243-2.252-.243-4.565-.243-6.817 0-1.826 0-3.713-.183-5.539-.67-8.886-1.886-17.834-3.712-26.598-.122-.73-.366-1.461-.61-2.13-.243-.853-.547-1.705-.73-2.618a51.065 51.065 0 0 1-.426-5.113c-.182-3.712-.121-7.547 0-11.26Z"
            />
        </svg>
    )
}
