import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setLoading, clearLoading } from './loadingSlice';

export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_AUDIENCE_URL }),
    endpoints: (builder) => ({
        saveOrder: builder.mutation({
            query: ({ accessToken, combinedData }) => {
                var formData = {
                    "combinedData": combinedData
                }

                return {
                    url: 'order/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        deleteOrder: builder.mutation({
            query: ({ accessToken, _id }) => {
                var formData = {
                    "_id": _id
                }

                return {
                    url: 'order/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderPriority: builder.mutation({
            query: ({ accessToken, serviceObjId, isPriority, expectedDelivery }) => {
                var formData = {
                    "serviceObjId": serviceObjId,
                    "isPriority": isPriority,
                    "expectedDelivery": expectedDelivery
                }

                return {
                    url: 'order/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderPriority: builder.mutation({
            query: ({ accessToken, _id, isPriority }) => {
                var formData = {
                    "_id": _id,
                    "isPriority": isPriority
                }

                return {
                    url: 'order/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderPatient: builder.mutation({
            query: ({ accessToken, orderObjId, patientInitials, dob, gender, options, patientConcerns }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "patientInitials": patientInitials,
                    "dob": dob,
                    "gender": gender,
                    "options": options,
                    "patientConcerns": patientConcerns
                }

                return {
                    url: 'orderpatient/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderPatient: builder.mutation({
            query: ({ accessToken, _id, patientInitials, dob, gender, option, patientConcerns }) => {
                var formData = {
                    "_id": _id,
                    "patientInitials": patientInitials,
                    "dob": dob,
                    "gender": gender,
                    "option": option,
                    "patientConcerns": patientConcerns
                }

                return {
                    url: 'orderpatient/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderClinical: builder.mutation({
            query: ({ accessToken, orderObjId, clinicalConditions }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "clinicalConditions": clinicalConditions,
                }

                return {
                    url: 'orderclinical/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderClinical: builder.mutation({
            query: ({ accessToken, _id, clinicalConditions }) => {
                var formData = {
                    "_id": _id,
                    "clinicalConditions": clinicalConditions,
                }

                return {
                    url: 'orderclinical/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderScans: builder.mutation({
            query: ({
                accessToken,
                orderObjId,
                fileType,
                fileFor,
                s3Url
            }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "fileType": fileType,
                    "s3Url": s3Url,
                    "fileFor": fileFor
                }
                return {
                    url: 'orderScans/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderScans: builder.mutation({
            query: ({
                accessToken,
                _id,
                s3Url
            }) => {
                var formData = {
                    "_id": _id,
                    "s3Url": s3Url
                }

                return {
                    url: 'orderScans/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderTreatmentGlsData: builder.mutation({
            query: ({ accessToken, orderObjId, treatmentGoals }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "treatmentGoals": treatmentGoals
                }

                return {
                    url: 'orderTreatmentGls/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderTreatmentGlsData: builder.mutation({
            query: ({ accessToken, _id, treatmentGoals }) => {
                var formData = {
                    "_id": _id,
                    "treatmentGoals": treatmentGoals
                }

                return {
                    url: 'orderTreatmentGls/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        deleteOrderTreatmentGlsData: builder.mutation({
            query: ({ accessToken, _id, treatmentGoals }) => {
                var formData = {
                    "_id": _id,
                    "treatmentGoals": treatmentGoals
                }

                return {
                    url: 'orderTreatmentGls/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderToothMovData: builder.mutation({
            query: ({ accessToken, orderObjId, toothMovementData }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "toothMovementData": toothMovementData
                }

                return {
                    url: 'orderToothMov/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderToothMovData: builder.mutation({
            query: ({ accessToken, _id, toothMovementData }) => {
                var formData = {
                    "_id": _id,
                    "toothMovementData": toothMovementData,
                }

                return {
                    url: 'orderToothMov/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        deleteOrderToothMovData: builder.mutation({
            query: ({ accessToken, _id, toothMovementData }) => {
                var formData = {
                    "_id": _id,
                    "toothMovementData": toothMovementData,
                }

                return {
                    url: 'orderToothMov/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveOrderTeethData: builder.mutation({
            query: ({ accessToken, orderObjId, teeth }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "toothData": teeth
                }

                return {
                    url: 'orderTooth/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderTeethData: builder.mutation({
            query: ({ accessToken, _id, teeth }) => {
                var formData = {
                    "_id": _id,
                    "toothData": teeth
                }

                return {
                    url: 'orderTooth/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getOrders: builder.mutation({
            query: (accessToken) => ({
                url: 'order/read',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }),
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getOrdersForAdmin: builder.mutation({
            query: (accessToken) => ({
                url: 'order/readad',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }),
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getOrdersForDentist: builder.mutation({
            query: (accessToken) => ({
                url: 'order/readdd',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }),
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getOrdersForServiceProvider: builder.mutation({
            query: (accessToken) => ({
                url: 'order/readsd',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            }),
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getOrderById: builder.mutation({
            query: ({ accessToken, orderId }) => {
                var formData = {
                    "orderObjId": orderId
                }

                return {
                    url: 'order/readbyid',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateOrderById: builder.mutation({
            query: ({ accessToken, orderId, currentStatus }) => {
                var formData = {
                    "_id": orderId,
                    "currentStatus": currentStatus
                }

                return {
                    url: 'order/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getServiceProviderByService: builder.mutation({
            query: ({ accessToken, serviceObjId }) => {
                var formData = {
                    "serviceObjId": serviceObjId
                }

                return {
                    url: '/spsbyservice',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        getServiceProviderOrders: builder.mutation({
            query: ({ accessToken, orderObjId }) => {
                var formData = {
                    "orderObjId": orderObjId
                }

                return {
                    url: '/sporders/read',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveServiceProviderOrders: builder.mutation({
            query: ({ accessToken, orderObjId, spuserAuthId, orderValue, portalValue, orderCurrency, userServiceObjId }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "spuserAuthId": spuserAuthId,
                    "orderValue": orderValue,
                    "orderCurrency": orderCurrency,
                    "userServiceObjId": userServiceObjId,
                    "portalValue": portalValue
                }

                return {
                    url: '/sporders/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateServiceProviderOrders: builder.mutation({
            query: ({ accessToken, orderObjId, orderValue, portalValue, orderCurrency }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "orderValue": orderValue,
                    "orderCurrency": orderCurrency,
                    "portalValue": portalValue
                }

                return {
                    url: '/sporders/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        saveCommOrders: builder.mutation({
            query: ({ accessToken, orderObjId, typofCommunication, communicationTile, imageurl, communicationText, commStatus, readStatus, parentObjId }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "imageurl": imageurl,
                    "typofCommunication": typofCommunication,
                    "communicationTile": communicationTile,
                    "communicationText": communicationText,
                    "commStatus": commStatus,
                    "readStatus": readStatus,
                    "parentObjId": parentObjId
                }

                return {
                    url: '/ordercomm/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        updateCommOrders: builder.mutation({
            query: ({ accessToken, _id, communicationTile, communicationText }) => {
                var formData = {
                    "_id": _id,
                    "communicationTile": communicationTile,
                    "communicationText": communicationText,
                }

                return {
                    url: '/ordercomm/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
        readCommOrders: builder.mutation({
            query: ({ accessToken, orderObjId, typofCommunication }) => {
                var formData = {
                    "orderObjId": orderObjId,
                    "typofCommunication": typofCommunication
                }

                return {
                    url: '/ordercomm/read',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
            onStart: (args, { dispatch }) => {
                dispatch(setLoading('saveCategory'));
            },
            onFinish: (args, { dispatch }) => {
                dispatch(clearLoading('saveCategory'));
            }
        }),
    }),
});

export const {
    useGetOrdersMutation,
    useSaveOrderMutation,
    useSaveOrderPriorityMutation,
    useGetOrdersForAdminMutation,
    useUpdateOrderPriorityMutation,
    useGetOrdersForDentistMutation,
    useGetOrdersForServiceProviderMutation,
    useGetOrderByIdMutation,
    useGetServiceProviderByServiceMutation,
    useGetServiceProviderOrdersMutation,
    useSaveServiceProviderOrdersMutation,
    useUpdateServiceProviderOrdersMutation,
    useUpdateOrderByIdMutation,
    useSaveOrderPatientMutation,
    useSaveOrderClinicalMutation,
    useUpdateOrderClinicalMutation,
    useUpdateOrderPatientMutation,
    useSaveOrderScansMutation,
    useUpdateOrderScansMutation,
    useSaveOrderTreatmentGlsDataMutation,
    useSaveOrderToothMovDataMutation,
    useUpdateOrderTreatmentGlsDataMutation,
    useUpdateOrderToothMovDataMutation,
    useDeleteOrderTreatmentGlsDataMutation,
    useDeleteOrderToothMovDataMutation,
    useSaveOrderTeethDataMutation,
    useUpdateOrderTeethDataMutation,
    useDeleteOrderMutation,
    useSaveCommOrdersMutation,
    useUpdateCommOrdersMutation,
    useReadCommOrdersMutation
} = ordersApi;