import React, { useEffect, useState } from "react";
import classes from "./Teeth.module.scss";
import {
  Teeth18,
  Teeth17,
  Teeth16,
  Teeth15,
  Teeth14,
  Teeth13,
  Teeth12,
  Teeth11,
  // right section import starts
  Teeth21,
  Teeth22,
  Teeth23,
  Teeth24,
  Teeth25,
  Teeth26,
  Teeth27,
  Teeth28,
  // bottom left section import starts
  Teeth48,
  Teeth47,
  Teeth46,
  Teeth45,
  Teeth44,
  Teeth43,
  Teeth42,
  Teeth41,
  // bottom right section import starts
  Teeth31,
  Teeth32,
  Teeth33,
  Teeth34,
  Teeth35,
  Teeth36,
  Teeth37,
  Teeth38,
} from "../../Assets/icons/teethNormal/index";

import {
  Teeth18Hover,
  Teeth17Hover,
  Teeth16Hover,
  Teeth15Hover,
  Teeth14Hover,
  Teeth13Hover,
  Teeth12Hover,
  Teeth11Hover,
  // right section import starts
  Teeth21Hover,
  Teeth22Hover,
  Teeth23Hover,
  Teeth24Hover,
  Teeth25Hover,
  Teeth26Hover,
  Teeth27Hover,
  Teeth28Hover,
  // bottom left section import starts
  Teeth48Hover,
  Teeth47Hover,
  Teeth46Hover,
  Teeth45Hover,
  Teeth44Hover,
  Teeth43Hover,
  Teeth42Hover,
  Teeth41Hover,
  // bottom right section import starts
  Teeth31Hover,
  Teeth32Hover,
  Teeth33Hover,
  Teeth34Hover,
  Teeth35Hover,
  Teeth36Hover,
  Teeth37Hover,
  Teeth38Hover,
} from "../../Assets/icons/teethHover/index";
import guideDownIcon from "../../Assets/images/guideDownIcon.png"
import guideIcon from "../../Assets/images/guideIcon.png"
import { GetToast } from "../Toast-Comps/GetToast";

export const TeethV2 = ({
  allTeethData,
  selectedTeeth,
  hideSelected,
  setSelectedTeeth,
  defaultNotation,
  notationVisible=true,
  title,
  missingTeeth,
  restrictedTeeth = [],
  restrictedTeethMessage,
  otherToothRestrictedMessage,
  missingTeethSelectable = false
}) => {

  console.log('selectedTeeth', selectedTeeth)

  // const [hoveredTeeth, setHoveredTeeth] = useState(null);
  const [notation, setNotation] = useState(defaultNotation ?? "UTN");

  const [teethNo11, setTeethNo11] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 11)[0] || { toothNumber: 11, _id: selectedTeeth?.includes(11) ? 'Checked' : '' });
  const [teethNo12, setTeethNo12] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 12)[0] || { toothNumber: 12, _id: selectedTeeth?.includes(12) ? 'Checked' : '' });
  const [teethNo13, setTeethNo13] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 13)[0] || { toothNumber: 13, _id: selectedTeeth?.includes(13) ? 'Checked' : '' });
  const [teethNo14, setTeethNo14] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 14)[0] || { toothNumber: 14, _id: selectedTeeth?.includes(14) ? 'Checked' : '' });
  const [teethNo15, setTeethNo15] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 15)[0] || { toothNumber: 15, _id: selectedTeeth?.includes(15) ? 'Checked' : '' });
  const [teethNo16, setTeethNo16] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 16)[0] || { toothNumber: 16, _id: selectedTeeth?.includes(16) ? 'Checked' : '' });
  const [teethNo17, setTeethNo17] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 17)[0] || { toothNumber: 17, _id: selectedTeeth?.includes(17) ? 'Checked' : '' });
  const [teethNo18, setTeethNo18] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 18)[0] || { toothNumber: 18, _id: selectedTeeth?.includes(18) ? 'Checked' : '' });
  const [teethNo21, setTeethNo21] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 21)[0] || { toothNumber: 21, _id: selectedTeeth?.includes(21) ? 'Checked' : '' });
  const [teethNo22, setTeethNo22] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 22)[0] || { toothNumber: 22, _id: selectedTeeth?.includes(22) ? 'Checked' : '' });
  const [teethNo23, setTeethNo23] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 23)[0] || { toothNumber: 23, _id: selectedTeeth?.includes(23) ? 'Checked' : '' });
  const [teethNo24, setTeethNo24] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 24)[0] || { toothNumber: 24, _id: selectedTeeth?.includes(24) ? 'Checked' : '' });
  const [teethNo25, setTeethNo25] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 25)[0] || { toothNumber: 25, _id: selectedTeeth?.includes(25) ? 'Checked' : '' });
  const [teethNo26, setTeethNo26] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 26)[0] || { toothNumber: 26, _id: selectedTeeth?.includes(26) ? 'Checked' : '' });
  const [teethNo27, setTeethNo27] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 27)[0] || { toothNumber: 27, _id: selectedTeeth?.includes(27) ? 'Checked' : '' });
  const [teethNo28, setTeethNo28] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 28)[0] || { toothNumber: 28, _id: selectedTeeth?.includes(28) ? 'Checked' : '' });
  const [teethNo31, setTeethNo31] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 21)[0] || { toothNumber: 31, _id: selectedTeeth?.includes(31) ? 'Checked' : '' });
  const [teethNo32, setTeethNo32] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 32)[0] || { toothNumber: 32, _id: selectedTeeth?.includes(32) ? 'Checked' : '' });
  const [teethNo33, setTeethNo33] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 33)[0] || { toothNumber: 33, _id: selectedTeeth?.includes(33) ? 'Checked' : '' });
  const [teethNo34, setTeethNo34] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 34)[0] || { toothNumber: 34, _id: selectedTeeth?.includes(34) ? 'Checked' : '' });
  const [teethNo35, setTeethNo35] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 35)[0] || { toothNumber: 35, _id: selectedTeeth?.includes(35) ? 'Checked' : '' });
  const [teethNo36, setTeethNo36] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 36)[0] || { toothNumber: 36, _id: selectedTeeth?.includes(36) ? 'Checked' : '' });
  const [teethNo37, setTeethNo37] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 37)[0] || { toothNumber: 37, _id: selectedTeeth?.includes(37) ? 'Checked' : '' });
  const [teethNo38, setTeethNo38] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 38)[0] || { toothNumber: 38, _id: selectedTeeth?.includes(38) ? 'Checked' : '' });
  const [teethNo41, setTeethNo41] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 41)[0] || { toothNumber: 41, _id: selectedTeeth?.includes(41) ? 'Checked' : '' });
  const [teethNo42, setTeethNo42] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 42)[0] || { toothNumber: 42, _id: selectedTeeth?.includes(42) ? 'Checked' : '' });
  const [teethNo43, setTeethNo43] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 43)[0] || { toothNumber: 43, _id: selectedTeeth?.includes(43) ? 'Checked' : '' });
  const [teethNo44, setTeethNo44] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 44)[0] || { toothNumber: 44, _id: selectedTeeth?.includes(44) ? 'Checked' : '' });
  const [teethNo45, setTeethNo45] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 45)[0] || { toothNumber: 45, _id: selectedTeeth?.includes(45) ? 'Checked' : '' });
  const [teethNo46, setTeethNo46] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 46)[0] || { toothNumber: 46, _id: selectedTeeth?.includes(46) ? 'Checked' : '' });
  const [teethNo47, setTeethNo47] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 47)[0] || { toothNumber: 47, _id: selectedTeeth?.includes(47) ? 'Checked' : '' });
  const [teethNo48, setTeethNo48] = useState(allTeethData?.filter((tooth) => tooth.toothNumber === 48)[0] || { toothNumber: 48, _id: selectedTeeth?.includes(48) ? 'Checked' : '' });

  const arrayOfLeftTeethData = [
    {
      initialState: teethNo18,
      setState: setTeethNo18,
      image: Teeth18,
      hoverImage: Teeth18Hover,
      teethNumber: 18,
      teethBottomNumber: 1,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo17,
      setState: setTeethNo17,
      image: Teeth17,
      hoverImage: Teeth17Hover,
      teethNumber: 17,
      teethBottomNumber: 2,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo16,
      setState: setTeethNo16,
      image: Teeth16,
      hoverImage: Teeth16Hover,
      teethNumber: 16,
      teethBottomNumber: 3,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo15,
      setState: setTeethNo15,
      image: Teeth15,
      hoverImage: Teeth15Hover,
      teethNumber: 15,
      teethBottomNumber: 4,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo14,
      setState: setTeethNo14,
      image: Teeth14,
      hoverImage: Teeth14Hover,
      teethNumber: 14,
      teethBottomNumber: 5,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo13,
      setState: setTeethNo13,
      image: Teeth13,
      hoverImage: Teeth13Hover,
      teethNumber: 13,
      teethBottomNumber: 6,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo12,
      setState: setTeethNo12,
      image: Teeth12,
      hoverImage: Teeth12Hover,
      teethNumber: 12,
      teethBottomNumber: 7,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo11,
      setState: setTeethNo11,
      image: Teeth11,
      hoverImage: Teeth11Hover,
      teethNumber: 11,
      teethBottomNumber: 8,
      guideImage: guideDownIcon
    },
  ]

  const arrayOfRightTeethData = [
    {
      initialState: teethNo21,
      setState: setTeethNo21,
      image: Teeth21,
      hoverImage: Teeth21Hover,
      teethNumber: 21,
      teethBottomNumber: 9,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo22,
      setState: setTeethNo22,
      image: Teeth22,
      hoverImage: Teeth22Hover,
      teethNumber: 22,
      teethBottomNumber: 10,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo23,
      setState: setTeethNo23,
      image: Teeth23,
      hoverImage: Teeth23Hover,
      teethNumber: 23,
      teethBottomNumber: 11,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo24,
      setState: setTeethNo24,
      image: Teeth24,
      hoverImage: Teeth24Hover,
      teethNumber: 24,
      teethBottomNumber: 12,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo25,
      setState: setTeethNo25,
      image: Teeth25,
      hoverImage: Teeth25Hover,
      teethNumber: 25,
      teethBottomNumber: 13,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo26,
      setState: setTeethNo26,
      image: Teeth26,
      hoverImage: Teeth26Hover,
      teethNumber: 26,
      teethBottomNumber: 14,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo27,
      setState: setTeethNo27,
      image: Teeth27,
      hoverImage: Teeth27Hover,
      teethNumber: 27,
      teethBottomNumber: 15,
      guideImage: guideDownIcon
    },
    {
      initialState: teethNo28,
      setState: setTeethNo28,
      image: Teeth28,
      hoverImage: Teeth28Hover,
      teethNumber: 28,
      teethBottomNumber: 16,
      guideImage: guideDownIcon
    },
  ]

  const arrayOfBottomRightTeethData = [
    {
      initialState: teethNo31,
      setState: setTeethNo31,
      image: Teeth31,
      hoverImage: Teeth31Hover,
      teethNumber: 31,
      teethBottomNumber: 24,
      guideImage: guideIcon
    },
    {
      initialState: teethNo32,
      setState: setTeethNo32,
      image: Teeth32,
      hoverImage: Teeth32Hover,
      teethNumber: 32,
      teethBottomNumber: 23,
      guideImage: guideIcon
    },
    {
      initialState: teethNo33,
      setState: setTeethNo33,
      image: Teeth33,
      hoverImage: Teeth33Hover,
      teethNumber: 33,
      teethBottomNumber: 22,
      guideImage: guideIcon
    },
    {
      initialState: teethNo34,
      setState: setTeethNo34,
      image: Teeth34,
      hoverImage: Teeth34Hover,
      teethNumber: 34,
      teethBottomNumber: 21,
      guideImage: guideIcon
    },
    {
      initialState: teethNo35,
      setState: setTeethNo35,
      image: Teeth35,
      hoverImage: Teeth35Hover,
      teethNumber: 35,
      teethBottomNumber: 20,
      guideImage: guideIcon
    },
    {
      initialState: teethNo36,
      setState: setTeethNo36,
      image: Teeth36,
      hoverImage: Teeth36Hover,
      teethNumber: 36,
      teethBottomNumber: 19,
      guideImage: guideIcon
    },
    {
      initialState: teethNo37,
      setState: setTeethNo37,
      image: Teeth37,
      hoverImage: Teeth37Hover,
      teethNumber: 37,
      teethBottomNumber: 18,
      guideImage: guideIcon
    },
    {
      initialState: teethNo38,
      setState: setTeethNo38,
      image: Teeth38,
      hoverImage: Teeth38Hover,
      teethNumber: 38,
      teethBottomNumber: 17,
      guideImage: guideIcon
    },
  ];

  const arrayOfBottomLeftTeethData = [
    {
      initialState: teethNo48,
      setState: setTeethNo48,
      image: Teeth48,
      hoverImage: Teeth48Hover,
      teethNumber: 48,
      teethBottomNumber: 32,
      guideImage: guideIcon
    },
    {
      initialState: teethNo47,
      setState: setTeethNo47,
      image: Teeth47,
      hoverImage: Teeth47Hover,
      teethNumber: 47,
      teethBottomNumber: 31,
      guideImage: guideIcon
    },
    {
      initialState: teethNo46,
      setState: setTeethNo46,
      image: Teeth46,
      hoverImage: Teeth46Hover,
      teethNumber: 46,
      teethBottomNumber: 30,
      guideImage: guideIcon
    },
    {
      initialState: teethNo45,
      setState: setTeethNo45,
      image: Teeth45,
      hoverImage: Teeth45Hover,
      teethNumber: 45,
      teethBottomNumber: 29,
      guideImage: guideIcon
    },
    {
      initialState: teethNo44,
      setState: setTeethNo44,
      image: Teeth44,
      hoverImage: Teeth44Hover,
      teethNumber: 44,
      teethBottomNumber: 28,
      guideImage: guideIcon
    },
    {
      initialState: teethNo43,
      setState: setTeethNo43,
      image: Teeth43,
      hoverImage: Teeth43Hover,
      teethNumber: 43,
      teethBottomNumber: 27,
      guideImage: guideIcon
    },
    {
      initialState: teethNo42,
      setState: setTeethNo42,
      image: Teeth42,
      hoverImage: Teeth42Hover,
      teethNumber: 42,
      teethBottomNumber: 26,
      guideImage: guideIcon
    },
    {
      initialState: teethNo41,
      setState: setTeethNo41,
      image: Teeth41,
      hoverImage: Teeth41Hover,
      teethNumber: 41,
      teethBottomNumber: 25,
      guideImage: guideIcon
    },
  ];

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
  };

  const handleTeethClickChange = (teeth) => {
    console.log('teeth click data', teeth)
    if (teeth.initialState._id === '') {
      //save
      //resp => 

      teeth.setState(preState => ({ ...preState, _id: 'Checked' }))
      setSelectedTeeth((prevTeeth) => {
        console.log('prevTeeth', prevTeeth)
        console.log('teethNumber', (defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber))
        if (prevTeeth.includes(defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber)) {
          return prevTeeth.filter((t) => t !== (defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber));
        } else {
          return [...prevTeeth, (defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber)];
        }
      });
    } else {
      //delete
      //resp =>
      teeth.setState(preState => ({ ...preState, _id: '' }))
      setSelectedTeeth((prevTeeth) => {
        console.log('prevTeeth', prevTeeth)
        console.log('teethNumber', (defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber))
        if (prevTeeth.includes(defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber)) {
          return prevTeeth.filter((t) => t !== (defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber));
        } else {
          return [...prevTeeth, (defaultNotation === "FDI" ? teeth.teethNumber :  teeth.teethBottomNumber)];
        }
      });
    }
  }

  const handleTeethClick = (teethNumber) => {
    setSelectedTeeth((prevTeeth) => {
      if (prevTeeth.includes(teethNumber)) {
        return prevTeeth.filter((t) => t !== teethNumber);
      } else {
        return [...prevTeeth, teethNumber];
      }
    });
  };

  // const handleMouseEnter = (teethNumber) => {
  //   setHoveredTeeth(teethNumber);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredTeeth(null);
  // };
  useEffect(() => {
  }, [selectedTeeth]);
  return (
    <>
      <div className={classes.headerContainer}>
        {title && <div className={classes.headerText}>{title ?? "Tooth Movement Restrictions"}</div>}
        {notationVisible && <div className={classes.radioButtons}>
          <label className="text-white">
            <input
              style={{ marginRight: "10px" }}
              type="radio"
              value="UTN"
              checked={notation === "UTN"}
              onChange={handleNotationChange}
              className="text-white"
            />
            UTN
          </label>
          <label className="text-white">
            <input
              style={{ marginRight: "10px" }}
              type="radio"
              value="FDI"
              checked={notation === "FDI"}
              onChange={handleNotationChange}
              className="text-white"
            />
            FDI
          </label>
        </div>}
      </div>
      <div className="md:grid relative bg-[#4e80f42d] p-5 w-fit flex flex-col md:grid-cols-2 grid-cols-1 grid-rows-2 text-white">
        <div className={classes.rightText}>R</div>
        <div className={classes.leftText}>L</div>
        <div className={classes.leftSectionWrapper}>
          {
            arrayOfLeftTeethData.map((item) => {
              return (
                <IndividualTeeth
                  notation={notation}
                  restrictedTeeth={restrictedTeeth}
                  restrictedTeethMessage={restrictedTeethMessage}
                  otherToothRestrictedMessage={otherToothRestrictedMessage}
                  hideSelected={hideSelected}
                  defaultNotation={defaultNotation}
                  teethNumber={item.teethNumber}
                  teethBottomNumber={item.teethBottomNumber}
                  Teeth={item.image}
                  HoveredTeeth={item.hoverImage}
                  GuideImage={item?.guideImage}
                  selected={item.initialState._id !== ''}
                  onClick={() => handleTeethClickChange(item)}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  missingTeeth={missingTeeth}
                  missingTeethSelectable={missingTeethSelectable}
                />
              )
            })
          }
        </div>
        <div className={classes.rightSectionWrapper}>
          {
            arrayOfRightTeethData.map((item) => {
              return (
                <IndividualTeeth
                  notation={notation}
                  restrictedTeeth={restrictedTeeth}
                  restrictedTeethMessage={restrictedTeethMessage}
                  otherToothRestrictedMessage={otherToothRestrictedMessage}
                  hideSelected={hideSelected}
                  defaultNotation={defaultNotation}
                  teethNumber={item.teethNumber}
                  teethBottomNumber={item.teethBottomNumber}
                  Teeth={item.image}
                  HoveredTeeth={item.hoverImage}
                  GuideImage={item?.guideImage}
                  selected={item.initialState._id !== ''}
                  onClick={() => handleTeethClickChange(item)}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  missingTeeth={missingTeeth}
                  missingTeethSelectable={missingTeethSelectable}
                />
              )
            })
          }
        </div>
        <div className={classes.bottomLeftSectionWrapper}>
          {
            arrayOfBottomLeftTeethData.map((item) => {
              return (
                <IndividualTeeth
                  notation={notation}
                  restrictedTeeth={restrictedTeeth}
                  restrictedTeethMessage={restrictedTeethMessage}
                  otherToothRestrictedMessage={otherToothRestrictedMessage}
                  hideSelected={hideSelected}
                  defaultNotation={defaultNotation}
                  teethNumber={item.teethNumber}
                  teethBottomNumber={item.teethBottomNumber}
                  Teeth={item.image}
                  HoveredTeeth={item.hoverImage}
                  GuideImage={item?.guideImage}
                  selected={item.initialState._id !== ''}
                  onClick={() => handleTeethClickChange(item)}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  missingTeeth={missingTeeth}
                  missingTeethSelectable={missingTeethSelectable}
                />
              )
            })
          }
        </div>
        <div className={classes.bottomRightSectionWrapper}>
          {
            arrayOfBottomRightTeethData.map((item) => {
              return (
                <IndividualTeeth
                  notation={notation}
                  restrictedTeeth={restrictedTeeth}
                  restrictedTeethMessage={restrictedTeethMessage}
                  otherToothRestrictedMessage={otherToothRestrictedMessage}
                  hideSelected={hideSelected}
                  defaultNotation={defaultNotation}
                  teethNumber={item.teethNumber}
                  teethBottomNumber={item.teethBottomNumber}
                  Teeth={item.image}
                  HoveredTeeth={item.hoverImage}
                  GuideImage={item?.guideImage}
                  selected={item.initialState._id !== ''}
                  onClick={() => handleTeethClickChange(item)}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                  missingTeeth={missingTeeth}
                  missingTeethSelectable={missingTeethSelectable}
                />
              )
            })
          }
          {/* <IndividualTeeth
            notation={notation}
            teethNumber={31}
            teethBottomNumber={24}
            Teeth={Teeth31}
            isBottom={true}
            HoveredTeeth={Teeth31Hover}
            selected={selectedTeeth.includes(31) || hoveredTeeth === 31}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={32}
            teethBottomNumber={23}
            Teeth={Teeth32}
            isBottom={true}
            HoveredTeeth={Teeth32Hover}
            selected={selectedTeeth.includes(32) || hoveredTeeth === 32}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={33}
            teethBottomNumber={22}
            Teeth={Teeth33}
            isBottom={true}
            HoveredTeeth={Teeth33Hover}
            selected={selectedTeeth.includes(33) || hoveredTeeth === 33}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={34}
            teethBottomNumber={21}
            Teeth={Teeth34}
            isBottom={true}
            HoveredTeeth={Teeth34Hover}
            selected={selectedTeeth.includes(34) || hoveredTeeth === 34}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={35}
            teethBottomNumber={20}
            Teeth={Teeth35}
            isBottom={true}
            HoveredTeeth={Teeth35Hover}
            selected={selectedTeeth.includes(35) || hoveredTeeth === 35}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={36}
            teethBottomNumber={19}
            Teeth={Teeth36}
            isBottom={true}
            HoveredTeeth={Teeth36Hover}
            selected={selectedTeeth.includes(36) || hoveredTeeth === 36}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={37}
            teethBottomNumber={18}
            Teeth={Teeth37}
            isBottom={true}
            HoveredTeeth={Teeth37Hover}
            selected={selectedTeeth.includes(37) || hoveredTeeth === 37}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <IndividualTeeth
            notation={notation}
            teethNumber={38}
            teethBottomNumber={17}
            Teeth={Teeth38}
            isBottom={true}
            HoveredTeeth={Teeth38Hover}
            selected={selectedTeeth.includes(38) || hoveredTeeth === 38}
            onClick={handleTeethClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          /> */}
        </div>
      </div>
    </>
  );
};

const IndividualTeeth = ({
  teethNumber,
  hideSelected,
  defaultNotation,
  teethBottomNumber,
  Teeth,
  isBottom,
  HoveredTeeth,
  selected,
  onClick,
  // onMouseEnter,
  // onMouseLeave,
  notation,
  missingTeeth,
  restrictedTeeth,
  restrictedTeethMessage,
  missingTeethSelectable,
  otherToothRestrictedMessage,
  GuideImage
}) => {
  const handleClick = (e) => {
    console.log("check")
    if(missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber) && !missingTeethSelectable){
      return;
    }
    else if(restrictedTeeth?.includes(teethNumber)){
      GetToast(restrictedTeethMessage ?? "Tooth is already selected in other types")
      return;
    }else if(missingTeethSelectable && missingTeeth?.length> 0 && !missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)){
      GetToast(otherToothRestrictedMessage ?? "Tooth is not selectable")
      return;
    }
    onClick(teethNumber);
  };

  // const handleMouseEnter = () => {
  //   onMouseEnter(teethNumber);
  // };

  return (
    <div
      className={classes.individualTeethWrapper}
      style={{ flexDirection: isBottom ? "column-reverse" : "column" }}
      onClick={handleClick}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={onMouseLeave}
    >
      <div 
        className={`${((hideSelected && selected) || missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber)) && !missingTeethSelectable ? "invisible": ""}`} 
      >
        {missingTeeth?.includes(notation === "FDI" ? teethNumber : teethBottomNumber) ? (missingTeethSelectable && selected ? <img src={GuideImage} className="max-h-[75px] max-w-[90%]"/> : null) : selected ? <HoveredTeeth /> : <Teeth />}
      </div>
      {(!defaultNotation || (defaultNotation && defaultNotation === "FDI")) && <div
        className={
          notation === "FDI" ? classes.teethNumber : classes.teethNumberSelected
        }
      >
        {teethNumber}
      </div>}
      {(!defaultNotation || (defaultNotation && defaultNotation !== "FDI")) && <div
        className={
          notation === "FDI" ? classes.teethNumberSelected : classes.teethNumber
        }
      >
        {teethBottomNumber}
      </div>}
    </div>
  );
};
