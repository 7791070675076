import { useEffect, useState } from "react";
import addIcon from "../../Assets/icons/addIcon.svg";
import { AiOutlineClose } from "react-icons/ai";
export default function RangeInputField({
  labelText,
  labelColor,
  labelClass,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validationFunctionName,
  margin,
  max,
  min,
  step = 1,
  unit=""
}) {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0 && validationFunctionName(value) === true) {
      setIfInvalid(false);
    }
  }, [validationFunctionName, value]);

  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className={`text-[16px] leading-4 text-white ${labelClass ?? ""}`}
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <div className="flex items-center gap-1">
        <input
          type="range"
          min={min}
          max={max}
          id={name}
          name={name}
          value={value}
          step={step}
          onChange={handleInputChange}
          placeholder={placeholder}
          style={{ marginBottom: `${margin}` }}
          onInvalid={(e) => {
            e.preventDefault();
            setIfInvalid(true);
            e.target.setCustomValidity("");
          }}
          onInput={(e) => {
            if (
              isSubmitted &&
              !validationFunctionName(value) &&
              isFieldRequired
            ) {
              setIfInvalid(false);
            }
          }}
          className={`border p-2 w-full text-[#555] text-[16px] leading-4 mt-2 mb-2 rounded-md h-10 ${(isSubmitted &&
            !validationFunctionName(value) &&
            isFieldRequired) ||
            ifInvalid
            ? "border-red-500"
            : ""
            }`}
          required={isFieldRequired}
        />
        <output className="text-white flex">{value} {unit}</output>
      </div>
      {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">
          {errorMessage}
        </p>
      )}
    </div>
  );
}
