import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Profile from "./Screens/Profile/Profile";
import Services from "./Screens/Services/Services";
import Login from "./Screens/Login/Login";
import ServiceProviderForm from "./Screens/Login/ServiceProvider/ServiceProviderForm";
import DentistForm from "./Screens/Login/Dentist/DentistForm";
import { useAuth0 } from "@auth0/auth0-react";
// toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dentist from "./Screens/User Management/Dentist";
import ServiceProvider from "./Screens/User Management/ServiceProvider";
import Categories from "./Screens/Services/Categories";
import Orders from "./Screens/Orders/Orders";
import ChangePassword from "./Screens/Profile/ChangePassword";
import AddNewOrder from "./Screens/Orders/AddNewOrder";
import { useGetProfileMutation } from "./services/user";
import OrthodonticOrderConfirmation from "./Screens/OrderConfirmation/OrthodonticOrderConfirmation";
import OrderDetailsAdmin from "./Screens/OrderDetailsAdmin/OrderDetailsAdmin";
import OrderDetailsServiceProvider from "./Screens/OrderDetailsServiceProvider/OrderDetailsServiceProvider";
import OrthodonticOrderDetails from "./Screens/Orders/OrthodonticOrderDetails";
import OrderDetailsDentist from "./Screens/OrderDetailsDentist/OrderDetailsDentist";
import DashboardSP from "./Screens/Dashboard/DashboardSP";
import DashboardDentist from "./Screens/Dashboard/DashboardDentist";
import { ServicesServiceProvider } from "./Screens/ServicesServiceProvider/ServicesServiceProvider";
import PaymentHistory from "./Screens/PaymentHistory/PaymentHistory";
import OrderDisputes from "./Screens/OrderDisputes.js/OrderDisputes";
import CrownAndBridgeOrderDetails from "./Screens/Orders/CrownAndBridgeOrderDetails";
import ImplantOrderDetails from "./Screens/Orders/ImplantOrderDetails";
import Questions from "./Screens/Services/Questions";
import Software from "./Screens/Services/Software";

function App() {
  let userRole = localStorage.getItem("crowdHireUserRole");
  let userCrowdHireId = localStorage.getItem("userCrowdHireId");
  let useractive = localStorage.getItem("useractive");
  const loginNavigate = useNavigate();
  const [getProfile] = useGetProfileMutation();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [emailFromUserProfile, setEmailFromUserProfile] = useState("");
  const [profileId, setProfileId] = useState("");
  const [userInfo, setUserInfo] = useState([
    {
      active: Number(useractive),
      crowdHireId: userCrowdHireId,
    },
  ]);
  const [isRoleLoading, setIsRoleLoading] = useState(false);

  const redirectIfRolePresent = (uRole, userCrowdHireId) => {
    setIsRoleLoading(false);
    switch (uRole) {
      case null:
        loginNavigate("/");
        break;
      // super admin
      case "89uij7":
        loginNavigate("/dashboard");
        break;
      // service provider
      case "34fs3":
        if (userCrowdHireId === "undefined" || undefined) {
          loginNavigate("/serviceProviderForm");
        } else {
          loginNavigate("/dashboard");
        }
        break;
      // dentist
      case "676dza2":
        if (userCrowdHireId === "undefined" || undefined) {
          loginNavigate("/dentistForm");
        } else {
          loginNavigate("/dashboard");
        }
        break;
      default:
        loginNavigate("/");
        break;
    }
  };

  useEffect(() => {
    const checkingRole = async () => {
      if (isAuthenticated) {
        setIsRoleLoading(true);
        if (userRole && userCrowdHireId) {
          redirectIfRolePresent(userRole, userCrowdHireId);
          return;
        }
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
          },
        });
        const response = await getProfile(accessToken).unwrap();
        console.log("response", response);
        if (response) {
          localStorage.setItem("crowdHireUserRole", response?.role);
          localStorage.setItem("userCrowdHireId", response?.chDetails[0]?.crowdHireId);
          localStorage.setItem("useractive", response?.chDetails[0]?.active);
          setEmailFromUserProfile(response["email"]);
          const userRoleFromResponse = response["role"];
          redirectIfRolePresent(userRoleFromResponse, response?.chDetails[0]?.crowdHireId);
          setUserInfo(response?.chDetails);
        }
      }
    };
    checkingRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { logout } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = "https://www.digitaldentition.com/coming-soon";
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="App font-roboto">
        <Routes>
          <Route exact path="/" element={isRoleLoading ? <SplashScreen /> : <Login />} />
          <Route
            exact
            path="/dashboard"
            element={
              <div className="bg-black text-white w-full h-screen flex items-center justify-center">
                <div className="flex flex-col items-center">
                  {/* <h1 className="text-4xl text-white font-bold text-center">
                    We are Comming Soon!
                  </h1>
                  <button
                    className="bg-[#FF7E00] text-white font-medium py-4 px-8  rounded-[10px] leading-5 my-6"
                    onClick={() => {
                      localStorage.clear();
                      logout({ returnTo: window.location.origin });
                    }}
                  >
                    Go Back
                  </button> */}
                </div>
              </div>
            }
          />
          <Route
            exact
            path="/profileinfo"
            element={
              <Profile
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
                userRole={userRole}
                userInfo={userInfo}
              />
            }
          />
          <Route
            exact
            path="/changepassword"
            element={
              <ChangePassword
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/servicecategories"
            element={
              <Services
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/softwarecategories"
            element={
              <Software
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/questions"
            element={
              <Questions
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/servicesSp"
            element={
              <ServicesServiceProvider
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/providerlevels"
            element={
              <Categories
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
                userRole={userRole}
                userInfo={userInfo}
              />
            }
          />
          <Route
            exact
            path="/serviceProviderForm"
            element={
              <ServiceProviderForm
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                setProfileId={setProfileId}
              />
            }
          />

          <Route
            exact
            path="/dentistForm"
            element={
              <DentistForm
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/serviceproviders"
            element={<ServiceProvider userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/dentist"
            element={<Dentist userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orders"
            element={<Orders userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/paymentHistory"
            element={<PaymentHistory userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDisputes"
            element={<OrderDisputes userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/addNewOrder"
            element={<AddNewOrder userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orthodonticsorder"
            element={<OrthodonticOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/crownandbridgeorder"
            element={<CrownAndBridgeOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/implantorder"
            element={<ImplantOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          {/* <Route
            exact
            path="/orderConfirmation"
            element={
              <OrthodonticOrderConfirmation userRole={userRole} userInfo={userInfo} />
            }
          /> */}
          <Route
            exact
            path="/orderDetailsAdmin"
            element={<OrderDetailsAdmin userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDetailsServiceProvider"
            element={<OrderDetailsServiceProvider userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDetailsDentist"
            element={<OrderDetailsDentist userRole={userRole} userInfo={userInfo} />}
          />
        </Routes>
        <ToastContainer className={"!w-[48vw]"} />
      </div>
    </>
  );
}

const SplashScreen = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <h1 className="text-4xl font-bold text-white">Loading the App...</h1>
    </div>
  );
};

export default App;
