import React from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";

// inner section imports
// modal imports
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";

import { CategoriesTab } from "../../Components/Services-Comps/CategoriesTab";
import { ServicesTab } from "../../Components/Services-Comps/ServicesTab";
import { ProfileInfoTab } from "../../Components/Profile-Comps/ProfileInfoTab";
import { ChangePasswordTab } from "../../Components/Profile-Comps/ChangePasswordTab";
import { TestominalsAndRatingsTab } from "../../Components/Profile-Comps/TestominalsAndRatingsTab";
import { SoftwareTab } from "../../Components/Software-Comps/SoftwareTab";
import { QuestionsTab } from "../../Components/Questions-Comps/QuestionsTab";

function Software({
  emailFromUserProfile,
  profileId,
  setProfileId,
  userRole,
  userInfo,
}) {
  const middleTabTitleArray = [
    "Service Categories",
    "Provider Levels",
    "Software Categories",
    "Questions",
  ];
  const middleTabContentObject = {
    "Service Categories": <ServicesTab />,
    "Provider Levels": <CategoriesTab />,
    "Software Categories": <SoftwareTab />,
    Questions: <QuestionsTab />,
  };
  const middleServiceProviderTabTitleArray = [
    "Profile Info",
    "Change Password",
    "Service Categories",
    "Testominals & Ratings",
  ];
  const middleServiceProviderTabContentObject = {
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
      />
    ),
    "Change Password": <ChangePasswordTab />,
    "Service Categories": <ServicesTab />,
    "Testominals & Ratings": <TestominalsAndRatingsTab />,
  };
  return (
    <div className="flex min-h-screen max-h-fit">
      <Navbar
        navActiveCss={navActiveCss}
        navHoverCss={navHoverCss}
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        middleSection={true}
        middleNavPosition={2}
        middleNavHeading="Services"
        middleTabTitleArray={
          userRole === "34fs3"
            ? middleServiceProviderTabTitleArray
            : middleTabTitleArray
        }
        middleTabContentObject={
          userRole === "34fs3"
            ? middleServiceProviderTabContentObject
            : middleTabContentObject
        }
        userRole={userRole}
        active="Profile"
      />
    </div>
  );
}
// table dummy data

export default Software;
