import React, { useState, useEffect } from "react";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";
import Modal from "react-modal";
import { useDeleteServicesMutation, useGetServicesMutation } from "../../services/services";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../../Screens/Login/Login";
import { GetToast } from "../Toast-Comps/GetToast";
import Notification from "../Notification/Notification";
import { useSelector } from "react-redux";
import InputFieldsServicesTab from "./InputFieldsServicesTab";
import DynamicTable from "./DynamicTable";
import addIcon from "../../Assets/icons/addCircle.svg";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import InputFieldsServicesCategoryTab from "./InputFieldsServicesCategoryTab";
import {
  useDeleteServicesCategoryMutation,
  useGetServicesCategoriesMutation,
} from "../../services/servicesCategory";

const headersForServicesTab = [
  { headerId: "serviceName", headerName: "Service Name", headerSort: true },
  // { headerId:'priceRangeFrom', headerName: "Price Range (min)", headerSort: true },
  // { headerId:'priceRangeTo', headerName: "Price Range (max)", headerSort: true },
  { headerId: "serviceCode", headerName: "Service Code", headerSort: true },
  { headerId: "sortOrder", headerName: "Sort Order", headerSort: true },
  {
    headerId: "serviceDescription",
    headerName: "Service Description",
    headerSort: true,
    rowAlignment: "left",
    headerAlignment: "left",
  },
  // { headerId: "images", headerName: "Images", headerSort: false },
  // { headerId: "videos", headerName: "Videos", headerSort: false },
  { headerId: "actions", headerName: "Actions", headerSort: false },
];

export const ServicesTab = () => {
  // modal state and styles
  const { user, isAuthenticated } = useAuth0();
  const [isServicesUpdated, setIsServicesUpdated] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditServiceModal, setIsEditServiceModal] = useState(false);
  const [isEditServiceCategoryModal, setIsEditServiceCategoryModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [getServicesCategories, { data: serviceCategoriesData }] =
    useGetServicesCategoriesMutation();
    const [deleteServices] = useDeleteServicesMutation();
    const [deleteServicesCategory] = useDeleteServicesCategoryMutation();
    const [editingServicesRow, setEditingServicesRow] = useState(null);
    const [editingServicesCategory, setEditingServicesCategory] = useState(null);
    const personalDetails = useSelector((state) => state.personalDetails);
    const [subServices, setSubServices] = useState([]);
    const [cardClicked, setCardClick] = useState(false);
    const [serviceCategoryModalIsOpen, setServiceCategoryModalIsOpen] = useState(false);
    const [selectedServiceCategory, setSelectedServiceCategory] = useState({});
    console.log("selectedServiceCategory", selectedServiceCategory)
    const [activePage, setActivePage] = useState({
      title: "InitialLoading",
      id: "",
      active: 0,
      serviceDescription: "",
      serviceSpecilization: "",
      price: "",
      offerPrice: "",
      tagsArray: [],
    });

  const openEditServiceModal = (row) => {
    setIsEditServiceModal(true);
    setModalIsOpen(true);
    setEditingServicesRow(row);
  };

  const getServicesCategoriesData = async (accessToken) => {
    const resp = await getServicesCategories(accessToken).unwrap();
    if (resp?.data && selectedServiceCategory?._id) {
      resp?.data?.forEach((d) => {
        if (d?._id === selectedServiceCategory?._id) {
          setSelectedServiceCategory(d);
        }
      });
    }
  };

  const readProfileInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesCategoriesData(accessToken);
  };

  const onDeleteServices = async (row) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await deleteServices({
      accessToken,
      _id: selectedServiceCategory?._id,
      serviceObjId: row?.serviceObjId,
    }).unwrap();
    await getServicesCategoriesData(accessToken);
    GetToast(resp["message"]);
  };

  const onDeleteServicesCategory = async (id) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await deleteServicesCategory({
      accessToken,
      _id: id,
    }).unwrap();
    await getServicesCategoriesData(accessToken);
    setSelectedServiceCategory({});
    GetToast(resp["message"]);
  };

  const onEditServicesCategory = async (card) => {
    setIsEditServiceCategoryModal(true);
    setServiceCategoryModalIsOpen(true);
    setEditingServicesCategory(card);
  };

  useEffect(() => {
    readProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServicesUpdated]);

  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditServiceModal(false);
    setEditingServicesRow(null);
  };

  const closeCategoryModal = () => {
    setServiceCategoryModalIsOpen(false);
    setIsEditServiceCategoryModal(false);
    setEditingServicesCategory(null);
  };

  const prepareServicesElements = () => {
    if (serviceCategoriesData?.data?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      serviceCategoriesData?.data.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            active={false}
            image={eachService?.serCategroyIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serCategoryName}
            serviceDescription={eachService?.serviceDescription}
            price={eachService.actualPrice ? eachService.actualPrice : ""}
            offerPrice={eachService.offerPrice ? eachService.offerPrice : ""}
            clickHandler={() => setSelectedServiceCategory(eachService)}
            handleDeleteIconClick={() => onDeleteServicesCategory(eachService?._id)}
            handleEditIconClick={() => onEditServicesCategory(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const handleCardOnClick = async (card) => {
    let userservicefound = serviceCategoriesData?.data.find((d) => d?._id === card.props.id);
    let found = serviceCategoriesData?.data.find(
      (d) => d?._id === userservicefound?.serviceObjId?._id
    );
    setActivePage({
      title: card.props.title,
      serviceDescription: card.props.serviceDescription,
      serviceSpecilization: card.props.serviceSpecilization,
      price: card.props.price,
      offerPrice: card.props.offerPrice,
      tagsArray: card.props.tagsArray,
      id: card.props.id,
      active: card.props.active,
      serviceId: userservicefound?.serviceObjId?._id,
    });
    if (found?.subservices) {
      setSubServices(
        found?.subservices?.map((d) => {
          let found = serviceCategoriesData?.data.find(
            (dd) => dd?.userSelectedService?._id === d?._id
          );
          return { ...d, selected: found ? true : false };
        })
      );
    }
    setCardClick(true);
  };

  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Settings <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary"> Services</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {personalDetails.data[0]?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {/* <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
            CH00001
            <img
              className="relative top-[4px] right-0"
              src={greenTick}
              alt=""
            />
          </button>
          <img src={bell} alt="" /> */}
          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-white">Services</p>
        </div>
      </div>
      <div className="bg-secondary py-4 px-4 rounded-md h-[80vh] overflow-y-auto">
        <div className="flex w-full justify-between items-center mb-2">
          <p className="text-xl font-semibold text-white">Categories</p>
          <button
            className="bg-[#FF7E00] px-4 py-2 font-sm text-white rounded-md flex items-center gap-2"
            onClick={() => setServiceCategoryModalIsOpen(true)}
          >
            <img className="" src={addIcon} alt="" />
            Add Category
          </button>
        </div>
        {serviceCategoriesData?.data?.length === 0 ? (
          <div
            role="status"
            className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
          >
            <div className="w-full flex gap-5">
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            </div>
          </div>
        ) : (
          <div className="CAROUSEL mb-8">
            <div className="md:block hidden">
              <CustomCarousel
                rows={1}
                cols={8}
                gapY={20}
                gapX={20}
                // numberOfSliders={[
                //   // these are three slides and three arrays of cards for each slide
                //   { 0: prepareServicesElements() },
                // ]}
                // CHANGE DATA HERE
                completeSliders={prepareServicesElements()}
                numberOfCardsShownInSingleSlide={8}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
            <div className="md:hidden block">
              <CustomCarousel
                rows={1}
                cols={2}
                gapY={20}
                gapX={20}
                completeSliders={prepareServicesElements()}
                numberOfCardsShownInSingleSlide={8}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
          </div>
        )}
        {activePage.title !== "InitialLoading" && selectedServiceCategory?._id && (
          <>
            <div className="bg-white bg-opacity-10 w-full h-[1px] mb-3"></div>
            <div>
              <div className="flex w-full justify-end items-center">
                <button
                  className="bg-[#FF7E00] px-4 py-2 font-sm text-white rounded-md flex items-center gap-2"
                  onClick={() => setModalIsOpen(true)}
                >
                  <img className="" src={addIcon} alt="" />
                  Add Service
                </button>
              </div>
              <DynamicTable
                key={selectedServiceCategory?._id}
                headers={headersForServicesTab}
                data={selectedServiceCategory?.catservices || []}
                includeImgVideoButton={true}
                serviceTable={true}
                onClickEditRow={openEditServiceModal}
                onClickDeleteRow={onDeleteServices}
              />
            </div>
          </>
        )}
      </div>

      {/* Service Category Modal */}
      <Modal
        isOpen={serviceCategoryModalIsOpen}
        onRequestClose={closeCategoryModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeCategoryModal}>
              <IoIosClose className="ml-auto text-white text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditServiceCategoryModal ? "Edit Service Category" : "Add Service Category"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldsServicesCategoryTab
                editingServicesRow={editingServicesCategory}
                isEditModal={isEditServiceCategoryModal}
                closeCategoryModal={closeCategoryModal}
                getData={getServicesCategoriesData}
              />
            </div>
          </div>
        </>
      </Modal>

      {/* Services Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeModal}>
              <IoIosClose className="ml-auto text-white text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditServiceModal ? "Edit Service" : "Add Service"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldsServicesTab
                editingServicesRow={editingServicesRow}
                isEditModal={isEditServiceModal}
                closeModal={closeModal}
                getData={getServicesCategoriesData}
                selectedServiceCategory={selectedServiceCategory}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
