const patientDetailsData = {
  patientInitials: "",
  detailsAnonymized: false,
  dob: "",
  gender: "",
  address: "",
  country: "",
  pinCode: "",
  location: {},
};

const uploadFilesData = {
  photographs: {
    imageOne: { fileType: "", s3Url: null, _id: "" },
    imageTwo: { fileType: "", s3Url: null, _id: "" },
    imageThree: { fileType: "", s3Url: null, _id: "" },
    imageFour: { fileType: "", s3Url: null, _id: "" },
    imageFive: { fileType: "", s3Url: null, _id: "" },
    imageSix: { fileType: "", s3Url: null, _id: "" },
    imageSeven: { fileType: "", s3Url: null, _id: "" },
    imageEight: { fileType: "", s3Url: null, _id: "" },
    imageNine: { fileType: "", s3Url: null, _id: "" },
    imageTen: { fileType: "", s3Url: null, _id: "" },
  },
  radiographs: {
    orthopantomogram: { fileType: "", s3Url: null, _id: "" },
    periapicalXrays: { fileType: "", s3Url: null, _id: "" },
    cephalogram: { fileType: "", s3Url: null, _id: "" },
  },
  intraOralScans: {
    upperScan: { fileType: "", s3Url: null, _id: "" },
    lowerScan: { fileType: "", s3Url: null, _id: "" },
    biteScan1: { fileType: "", s3Url: null, _id: "" },
    biteScan2: { fileType: "", s3Url: null, _id: "" },
  },
  rawDicomData: {
    fileOne: { fileType: "", s3Url: null, _id: "" },
    fileTwo: { fileType: "", s3Url: null, _id: "" },
    fileThree: { fileType: "", s3Url: null, _id: "" },
  },
};

const disclaimersData = {
  isTncChecked: false,
  tncDocument: null,
};

export const crownAndBridgeInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    services: [],
    crownType: [],
    veneerType: [],
    cementGap: 0,
    interproximalContacts: "",
    occlusalContacts: "",
    ponticDesign: "",
    needDesignPreview: null,
    processType: {
      process: "",
      preferredRestorativeSpace: 0,
    },
    designFileNeeded: null,
    preferredSoftware: "",
    constructionFileNeeded: null,
    printableModelRequired: {
      modelRequired: null,
      includeDies: null,
    },
    restorationLacksOC: "",
    additionalInstructions: "",
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const dentalImplantInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    teethToExtract: {
      teethToExtract: null,
      teeth: [],
    },
    anyTeethMobile: {
      anyTeethMobile: null,
      teeth: [],
    },
    services: [],
    guideType: {
      option: "",
      incorporateBoneReduction: null,
      additionalStackableOptions: [],
      conversionDentureAvailable: null,
      digitalConversionDentureRequired: null,
      prosthesisBeUsed: null,
      abutmentPreferences: "",
    },
    surgeryDate: "",
    preferredImplantCompany: "",
    implantLine: "",
    implantSurgicalKit: {
      option: "",
      densahBursUsed: null,
      densahBurGuideDesired: null,
      flapBeRaised: null,
    },
    guidanceLevel: "",
    servicesRequired: [],
    additionalInstructions: "",
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const orthodonticInitialOrderDetails = {
  expectedDelivery: "",
  serviceObjId: "",
  isPriority: false,
  patientDetails: patientDetailsData,
  caseDetails: {
    options: "",
    clinicalConditions: [],
    archesToTreat: [],
    molarOcclusalGoals: "",
    midline: [],
    missingTeeth: {
      hasMissingTeeth: null,
      teeth: [],
    },
    teethNeedToMove: {
      needToMove: null,
      teeth: [],
    },
    ipr: "",
    overjetCorrection: {
      option: "",
      improveLength: 0,
    },
    overbiteCorrection: {
      option: "",
      improveLength: 0,
    },
    crossbiteCorrection: "",
    attachmentsAllowed: null,
    retainerRequired: null,
    additionalInstructions: "",
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};
