import React from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";
// navbar icon imports

import "./profile.css";
import {
  iconArray,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import { ProfileInfoTab } from "../../Components/Profile-Comps/ProfileInfoTab";
import { ChangePasswordTab } from "../../Components/Profile-Comps/ChangePasswordTab";
import { ServicesTab } from "../../Components/Services-Comps/ServicesTab";
import { TestominalsAndRatingsTab } from "../../Components/Profile-Comps/TestominalsAndRatingsTab";

function ChangePassword({
  emailFromUserProfile,
  profileId,
  setProfileId,
  userRole,
  userInfo
}) {
  const middleTabTitleArray = ["Profile Info", "Change Password"];
  const middleTabContentObject = {
    "Change Password": <ChangePasswordTab />,
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
      />
    ),
  };
  const middleServiceProviderTabTitleArray = [
    "Profile Info",
    "Change Password",
    //"Services",
    // "Testominals & Ratings",
  ];
  const middleServiceProviderTabContentObject = {
    "Change Password": <ChangePasswordTab />,
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
      />
    ),

    "Service Categories": <ServicesTab />,
    // "Testominals & Ratings": <TestominalsAndRatingsTab />,
  };
  const navActiveCss =
    "relative before:absolute before:left-[-15px] md:before:left-[-30px] before:top-0 before:h-[20px] before:w-[2px] before:bg-white";
  const navHoverCss =
    "hover:before:absolute  before:left-[-15px] md:hover:before:left-[-30px] hover:before:top-0 hover:before:h-[20px] hover:before:w-[2px] hover:before:bg-white ";
  return (
    <div className="flex min-h-screen max-h-fit">
      <Navbar
        navActiveCss={navActiveCss}
        navHoverCss={navHoverCss}
        iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
        middleSection={true}
        middleNavPosition={1}
        middleNavHeading={"Profile"}
        middleTabTitleArray={
          userRole === "34fs3"
            ? middleServiceProviderTabTitleArray
            : middleTabTitleArray
        }
        middleTabContentObject={
          userRole === "34fs3"
            ? middleServiceProviderTabContentObject
            : middleTabContentObject
        }
        userRole={userRole}
        active='Profile'
      />
    </div>
  );
}

export default ChangePassword;
