import React, { useEffect, useRef } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";

const LocationInputV2 = ({ placeholder, name, setValue, setLocation, setPinCode, setCountry, setLatitude, setLongitude }) => {
  const inputRef = useRef();

  const placeChangeHandler = () => {
    const [place] = inputRef.current.getPlaces();
    // if (!place.geometry || !place.geometry.location) {
    // User entered the name of a Place that was not suggested and
    // pressed the Enter key, or the Place Details request failed.
    // alert("this location not available");
    // }
    if (place.geometry.viewport || place.geometry.location) {
      // do something
      console.log(place);
      place.address_components.forEach((d) => {
        if (d.types.includes("country") && setCountry) {
          setCountry(d.long_name);
        }
        if (d.types.includes("postal_code") && setPinCode) {
          setPinCode(d.long_name);
        }
      });
      if (setLocation) setLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
      if (setLatitude) setLatitude(place.geometry.location.lat());
      if (setLongitude) setLongitude(place.geometry.location.lng());
      if (setValue) setValue(place.formatted_address);
    }
  };

  return (
    <div className="relative">
      <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={placeChangeHandler}>
        <input type="text" name={name ?? "google-places-searchinput"} className="border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-black" placeholder={placeholder ?? "Enter Location"} />
      </StandaloneSearchBox>
    </div>
  );
};

export default LocationInputV2;
