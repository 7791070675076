import React, { useRef, useState } from "react";
import people from "../../Assets/images/People.svg";
import bgHover from "../../Assets/images/bgHover.svg";
import bgNormal from "../../Assets/images/bgNormal.svg";
import { CircularCheckbox } from "./CircularForm";
function ServiceCarouselTile({
  value,
  header,
  isAdmin,
  boxSelected,
  setBoxSelected,
  selected,
}) {
  const handleBoxSelect = (event) => {
    setBoxSelected(event.target.value);
  };
  const childDivRef = useRef(null);
  const handleHover = (event) => {
    if (childDivRef.current) {
      childDivRef.current.style.backgroundImage = `url(${bgHover})`;
    }
  };

  const handleMouseLeave = (event) => {
    if (childDivRef.current) {
      childDivRef.current.style.backgroundImage = `url(${bgNormal})`;
    }
  };

  return (
    <div
      // onMouseEnter={handleHover}
      // onMouseLeave={handleMouseLeave}
      className={
        (header === selected) ? 
        "w-full h-[156px] rounded-xl overflow-hidden cursor-pointer text-white"
        : "w-full h-[156px] rounded-xl overflow-hidden cursor-pointer  text-white bg-container-class"
      }
      onClick={() => { }}
    >
      <div
        ref={childDivRef}
        style={{
          // backgroundImage: (header === selected) ? `url(${bgHover})` : `url(${bgNormal})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "fit-content",
        }}
        className={ 
          (header === selected) ?
          `  bg-gradient-to-r from-orange-700 to-red-700  py-[20px] px-4 w-full  font-bold text-[16px] uppercase ${isAdmin ? "flex justify-between items-center" : ""} `
          :
          `bg-main-class py-[20px] px-4 w-full  font-bold text-[16px] uppercase ${isAdmin ? "flex justify-between items-center" : ""} `
         }
      >
        <div>{header}</div>
        {isAdmin && (
          <CircularCheckbox
            borderColor={"#999999"}
            backgroundColor={"#000"}
            value={header}
            checked={boxSelected === header}
            onChange={handleBoxSelect}
          />
        )}
      </div>
      <div className="bg-backgroundV2 flex items-center justify-center gap-4 py-4">
        <img
          src={people}
          className="h-[45px] !w-[45px] object-contain"
          alt="people"
        />
        <div className="">
          <p className=" font-semibold text-white text-[32px]">{value}</p>
          <p className={header === selected ? "text-[#fff] text-[16px]" : "text-[#777] text-[16px]"}>Active Users Current</p>
        </div>
      </div>
    </div>
  );
}

export default ServiceCarouselTile;
