import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const serviceCategoryApi = createApi({
  reducerPath: "serviceCategoryApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_AUDIENCE_URL }),
  endpoints: (builder) => ({
    saveServicesCategory: builder.mutation({
      query: ({
        accessToken,
        serCategoryName,
        sortOrder,
        serCategoryDescription,
        serCategoryIcon,
      }) => {
        var formData = {
          serCategoryName: serCategoryName,
          sortOrder: sortOrder,
          serCategoryDescription: serCategoryDescription,
          serCategroyIcon: serCategoryIcon,
        };

        return {
          url: "servcategory/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateServicesCategory: builder.mutation({
      query: ({
        accessToken,
        _id,
        serCategoryName,
        serCategoryDescription,
        serCategoryIcon,
        sortOrder,
      }) => {
        var formData = {
          _id: _id,
          serCategoryName: serCategoryName,
          serCategoryDescription: serCategoryDescription,
          sortOrder: sortOrder,
        };
        if (serCategoryIcon) {
          formData.serCategroyIcon = serCategoryIcon;
        }

        return {
          url: "servcategory/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteServicesCategory: builder.mutation({
      query: ({ accessToken, _id }) => {
        var formData = {
          _id: _id,
        };
        return {
          url: "servcategory/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getServicesCategories: builder.mutation({
      query: (accessToken) => ({
        url: "servcategory/read",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
  }),
});

export const {
  useSaveServicesCategoryMutation,
  useUpdateServicesCategoryMutation,
  useDeleteServicesCategoryMutation,
  useGetServicesCategoriesMutation,
} = serviceCategoryApi;
