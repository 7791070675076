export const crownTermsAndConditions = [
  "All Crown and Bridge planning services are ultimately to be evaluated and accepted by the treating Dentist. Progression of patient treatment implies that the treating Dentist has made an independent assessment of the treatment plan, considered all options available and is conscious of the risks involved.",
  "The portal does not accept any liability for its treatment planning services and all responsibility is assumed only by the treating Dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const implantTermsAndConditions = [
  "All Dental Implant planning services are ultimately to be evaluated and accepted by the treating Dentist. Progression of patient treatment implies that the treating Dentist has made an independent assessment of the treatment plan, considered all options available and is conscious of the risks involved.",
  "The portal does not accept any liability for its treatment planning services and all responsibility is assumed only by the treating Dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];

export const orthodonticTermsAndConditions = [
  "All Orthodontic planning services are ultimately to be evaluated and accepted by the treating Dentist. Progression of patient treatment implies that the treating Dentist has made an independent assessment of the treatment plan, considered all options available and is conscious of the risks involved.",
  "The portal does not accept any liability for its treatment planning services and all responsibility is assumed only by the treating Dentist",
  "The portal also does not accept any liability for treatment outcomes as a result of the treatment plan, as outcomes are multi-factorial and are affected by patient compliance as well.",
];
