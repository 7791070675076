import { useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

export default function PriceInputField({
    labelText,
    labelColor,
    errorMessage,
    placeholder,
    isFieldRequired,
    htmlFor,
    name,
    value,
    handleInputChange,
    isSubmitted,
    validationFunctionName,
    margin,
    currency,
    userValue,
    labelTooltipId, 
    labelTooltipContent,
    labelTooltipPosition
}) {
    const [ifInvalid, setIfInvalid] = useState(false);

    useEffect(() => {
        if (value?.length > 0 && validationFunctionName(value) === true) {
            setIfInvalid(false);
        }
    }, [validationFunctionName, value]);

    return (
        <div className="relative">
            <label
                htmlFor={htmlFor}
                className="text-[16px] leading-4 text-white flex items-center my-1"
                data-tooltip-id={labelTooltipId ?? ""} 
                data-tooltip-content={labelTooltipContent ?? ""} 
            >
                {labelText}
                {isFieldRequired ? <span className="text-red-500">*</span> : ""}
                {labelTooltipId && (
                    <span data-tooltip-id={labelTooltipId ?? ""} data-tooltip-content={labelTooltipContent ?? ""} data-tooltip-place={labelTooltipPosition ?? "bottom"}>
                    <AiFillInfoCircle className="ml-2" />
                    <Tooltip id={labelTooltipId ?? ""} />
                    </span>
                )}
            </label>
            <div className="flex w-full" >
                <p className="h-10 mt-2 text-gray-500 mb-4 p-2">
                    {currency}
                </p>
                <div className="w-full">
                    <input
                        type="text"
                        id={name}
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        onInvalid={(e) => {
                            e.preventDefault();
                            setIfInvalid(true);
                            e.target.setCustomValidity("");
                        }}
                        onInput={(e) => {
                            if (
                                isSubmitted &&
                                !validationFunctionName(value) &&
                                isFieldRequired
                            ) {
                                setIfInvalid(false);
                            }
                        }}
                        className={`border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-secondary ${(isSubmitted && !validationFunctionName(value) && isFieldRequired) ||
                            ifInvalid
                            ? "border-red-500"
                            : ""
                            }`}
                        required={isFieldRequired}
                    />
                    {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
                        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
                            {errorMessage}
                        </p>
                    )}
                    {ifInvalid && (
                        <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
            <div className="flex w-full" >
                <p className="h-10 mt-2 text-gray-500 mb-4 p-2">
                    USD
                </p>
                <div className="w-full">
                    <input
                        type="text"
                        disabled={true}
                        value={userValue}
                        className={`border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-backgroundV2 ${(isSubmitted && !validationFunctionName(value) && isFieldRequired) ||
                            ifInvalid
                            ? "border-red-500"
                            : ""
                            }`}
                    />
                </div>
            </div>
        </div>
    );
}
