import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";
// navbar icon imports

import "./profile.css";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import { ProfileInfoTab } from "../../Components/Profile-Comps/ProfileInfoTab";
import { ChangePasswordTab } from "../../Components/Profile-Comps/ChangePasswordTab";
import { ServicesTab } from "../../Components/Services-Comps/ServicesTab";
import { TestominalsAndRatingsTab } from "../../Components/Profile-Comps/TestominalsAndRatingsTab";
import { useReadPersonalDetailsMutation } from "../../services/user";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { setUserPersonalDetails } from "../../reducers/PersonalDetailsReducer";

function Profile({ emailFromUserProfile, profileId, setProfileId, userRole, userInfo }) {
  // debugger;
  
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();
  
  const {data: personalDetails} = useSelector((state) => state.personalDetails);
  
  const readProfileInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await readPersonalDetails({ accessToken }).unwrap((res) => res);
    dispatch(setUserPersonalDetails(response?.data));
  };

  useEffect(() => {
    readProfileInfo();
  }, []);

  const middleTabTitleArray = [
    "Profile Info", 
    // "Change Password"
  ];

  const middleTabContentObject = {
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
        userInfo={userInfo}
        userRole={userRole}
      />
    ),
    "Change Password": <ChangePasswordTab userInfo={userInfo}
      userRole={userRole} />,
  };
  const middleServiceProviderTabTitleArray = [
    "Profile Info",
    // "Change Password",
    //"Services",
    // "Testominals & Ratings",
  ];
  const middleServiceProviderTabContentObject = {
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
        userInfo={userInfo}
        userRole={userRole}
      />
    ),
    "Change Password": <ChangePasswordTab userInfo={userInfo}
      userRole={userRole} />,
    "Service Categories": <ServicesTab userInfo={userInfo}
      userRole={userRole} />,
    // "Testominals & Ratings": <TestominalsAndRatingsTab />,
  };
  return (
    <div className="flex min-h-screen max-h-fit">
      <Navbar
        navActiveCss={navActiveCss}
        navHoverCss={navHoverCss}
        iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
        middleSection={true}
        middleNavHeading={"Profile"}
        middleNavPosition={0}
        middleTabTitleArray={
          userRole === "34fs3"
            ? middleServiceProviderTabTitleArray
            : middleTabTitleArray
        }
        middleTabContentObject={
          userRole === "34fs3"
            ? middleServiceProviderTabContentObject
            : middleTabContentObject
        }
        userRole={userRole}
        active='Profile'
      />
    </div>
  );
}

export default Profile;
