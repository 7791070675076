import { useRef, useState } from "react";
import Modal from "react-modal";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import { AiOutlineClose } from "react-icons/ai";
import SignatureCanvas from "react-signature-canvas";
import { useSaveFileMutation } from "../../services/uploadFiles";
import { useAuth0 } from "@auth0/auth0-react";

export const DisclaimerSection = ({ submitOrderData, setActiveTab, tncList }) => {
  const [saveFile] = useSaveFileMutation();
  const { getAccessTokenSilently } = useAuth0();

  const [isTncChecked, setIsTncChecked] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  console.log("fileUrl", fileUrl);
  // eslint-disable-next-line no-unused-vars
  const [isDisclaimerFormSubmitted, setIsDisclaimerFormSubmitted] = useState(false);
  const [isDisclaimerFormValid, setIsDisclaimerFormValid] = useState(true);

  const [userSignature, setUserSignature] = useState(null);
  const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false);
  const signPadRef = useRef();

  const clearCanvas = () => {
    signPadRef.current.clear();
  };

  const updateSignature = () => {
    let signature = signPadRef.current.getTrimmedCanvas().toDataURL("image/png");
    setUserSignature(signature);
    return signature;
  };

  const getTncPdfFormat = (tempSignature) => {
    return (
      <div className="w-[500px] font-medium text-black bg-white font-[sans-serif]">
        <h1 className="text-center text-xl font-bold">Terms & Conditions</h1>
        <ul className="list-none w-full text-sm flex flex-col gap-2 py-3 px-3 pl-5">
          {tncList?.map((d, i) => {
            return <li key={"tnc-list-item-preview" + i}>• {d}</li>;
          })}
        </ul>
        {tempSignature && (
          <div className="flex justify-end">
            <img src={tempSignature} alt="User Signature" className="md:w-40 mt-3" />
          </div>
        )}
      </div>
    );
  };

  const saveDocument = async (file) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    console.log("file", file);
    const tempFile = new File([file], new Date().getTime() + ".pdf");
    console.log("tempFile", tempFile);
    const resp = await saveFile({
      accessToken,
      file: tempFile,
    });
    return resp;
  };

  const generatePDFDocument = (tempSignature) => {
    var doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [780, 540],
      hotfixes: ["px_scaling"],
    });
    doc.html(ReactDOMServer.renderToStaticMarkup(getTncPdfFormat(tempSignature)), {
      callback: async function (doc) {
        doc.save("Tnc_agreement_" + new Date().getTime());
        const res = await saveDocument(doc.output("blob"));
        setFileUrl(res?.data?.url ?? null);
      },
      margin: [25, 20, 30, 20],
      width: 500,
      windowWidth: 500,
    });
  };

  const handleInputChange = (event) => {
    if (event.currentTarget.checked) {
      setSignatureModalIsOpen(true);
    } else {
      setIsTncChecked(false);
      setUserSignature(null);
    }
  };
  const validateTncChecked = (value) => {
    if (value) {
      return true;
    } else {
      return false;
    }
  };
  const submitDisclaimerForm = () => {
    const isTncCheckedValid = !isFieldRequired("tnc") || validateTncChecked(isTncChecked);
    setIsDisclaimerFormValid(isTncCheckedValid);
    setIsDisclaimerFormSubmitted(true);

    if (isTncCheckedValid) {
      console.log("submit the form");
      submitOrderData({
        isTncChecked: isTncChecked,
        tncDocument: null,
      });
    } else {
      console.log("not checked");
    }
  };
  const isFieldRequired = (fieldName) => {
    const requiredFields = ["tnc"];
    return requiredFields.includes(fieldName);
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="text-medium text-[24px] text-white">Disclaimers</div>

        <ul className=" list-[circle] pl-3 text-base text-white flex flex-col gap-2 my-4">
          {tncList?.map((d, i) => {
            return <li key={"tnc-list-item-preview" + i}>{d}</li>;
          })}
        </ul>
        <div className="relative">
          <input
            type="checkbox"
            name="tnc"
            id="tnc"
            checked={isTncChecked}
            onChange={handleInputChange}
            required={isFieldRequired("tnc")}
          />
          <label className="text-[16px] leading-4 text-[#868686] ml-2" htmlFor="tnc">
            I understand and accept the terms and conditions outlined above.{" "}
            {isFieldRequired("tnc") ? <span className="text-red-500">*</span> : ""}
          </label>
          {isFieldRequired("tnc") &&
            validateTncChecked(isTncChecked) === false &&
            !isDisclaimerFormValid && (
              <p className="text-red-500  absolute -bottom-6 text-[15px] left-0  ">
                Please Check This to Continue.
              </p>
            )}
        </div>
        {userSignature && (
          <div className="">
            <img src={userSignature} alt="User Signature" className="bg-white md:w-52 w-[50%]" />
          </div>
        )}
      </div>

      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab("Upload Files");
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              // navigate("/addNewOrderDetails");
              //   submit form
              submitDisclaimerForm();
              //   setActiveTab("Upload PhotoGraphs");
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
      <Modal
        isOpen={signatureModalIsOpen}
        onRequestClose={() => {
          setSignatureModalIsOpen(false);
        }}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setSignatureModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-6">
              Signature in acceptance of terms and conditions
            </p>
            <div className="MODAL-TAB-SECTION flex-1 flex flex-col">
              <SignatureCanvas
                canvasProps={{ className: "bg-white", height: 400, onChange: updateSignature }}
                ref={signPadRef}
              />
              <div className="flex items-center justify-end gap-4 pt-4 mt-auto">
                <button
                  onClick={clearCanvas}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Try Again
                </button>
                <button
                  onClick={() => {
                    let tempSignature = updateSignature();
                    setIsTncChecked(true);
                    setSignatureModalIsOpen(false);
                    generatePDFDocument(tempSignature);
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#FF7E00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
