import React, { useState } from 'react';

function AccordionItem({ title, children, onToggle, isOpen, index }) {
  const toggleAccordion = () => {
    onToggle();
  };

  return (
    <div className="border-none mb-3">
      <div
        className={`side-card-gradient text-base ml-3 p-4 mr-3 font-medium text-white cursor-pointer  ${isOpen ? 'rounded-md' : 'rounded'
        }`}
        onClick={toggleAccordion}
      >
      <div className='flex'> 
      <div className=' flex justify-center items-center rounded-full h-8 w-8 text-[#4E81F4] bg-white border' > 
      {index}</div> 
      <div className='flex w-full justify-center ' > {title}</div> </div>
      </div>
      {isOpen && <div className="p-1">{children}</div>}
    </div>
  );
}

export { AccordionItem };
