import { createSlice } from "@reduxjs/toolkit";
import {
  crownAndBridgeInitialOrderDetails,
  dentalImplantInitialOrderDetails,
  orthodonticInitialOrderDetails,
} from "../Utils/orderFormInitialDetails";

const initialState = {
  // for Crown and Bridge Order Form
  crownAndBridgeOrderDetails: crownAndBridgeInitialOrderDetails,
  // for Dental Implant Order Form
  dentalImplantOrderDetails: dentalImplantInitialOrderDetails,
  // for Orthodontic Order Form
  orthodonticOrderDetails: orthodonticInitialOrderDetails,
};

export const orderFormSlice = createSlice({
  name: "orderForm",
  initialState,
  reducers: {
    setCrownAndBridgeOrderDetails: (state, action) => {
      state.crownAndBridgeOrderDetails = action.payload;
    },
    setDentalImplantOrderDetails: (state, action) => {
      state.dentalImplantOrderDetails = action.payload;
    },
    setOrthodonticOrderDetails: (state, action) => {
      state.orthodonticOrderDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCrownAndBridgeOrderDetails,
  setDentalImplantOrderDetails,
  setOrthodonticOrderDetails,
} = orderFormSlice.actions;
