import React, { useState, useEffect } from 'react';
import { useGetCurrenciesMutation } from '../../services/util';
import { useAuth0 } from '@auth0/auth0-react';

const CurrencyDropDown = ({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validationFunctionName,
  options,
}) => {

  // const { getAccessTokenSilently } = useAuth0();
  // const [getCurrencies] = useGetCurrenciesMutation();

  // const [currencies, setCurrencies] = useState([]);

  // const handleCurrencyChange = (event) => {
  //   const selectedCurrencyCode = event.target.value;
  //   // Handle the selected currency code here
  //   console.log(selectedCurrencyCode);
  // };

  // const getCurrencyAPIData = async (accessToken) => {
  //   const resp = await getCurrencies({ accessToken }).unwrap();
  //   setUserServices(resp.data);
  //   console.log(resp.data, "WHATHH")

  // };

  // const getCurrenciesData = async (id) => {
  //   const accessToken = await getAccessTokenSilently({
  //     authorizationParams: {
  //       audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
  //     },
  //   });
  //   if (accessToken) {
  //     await getCurrencyAPIData(accessToken);
  //   }
  // };

  console.log(options, "options")


  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);

  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className="text-white"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        onInvalid={(e) => {
          e.preventDefault();
          setIfInvalid(true);
          e.target.setCustomValidity("");
        }}
        onInput={(e) => {
          if (
            isSubmitted &&
            !validationFunctionName(value) &&
            isFieldRequired
          ) {
            setIfInvalid(false);
          }
        }}
        className={`border p-2 w-full text-white text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 bg-secondary ${(isSubmitted && !validationFunctionName(value) && isFieldRequired) ||
          ifInvalid
          ? "border-red-500"
          : ""
          }`}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options?.map((option) => (
          <option key={option.code} value={option.code}>
            {option.code} - {option.currency}
          </option>
        ))}
      </select>
      {isSubmitted && !validationFunctionName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default CurrencyDropDown;
