import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    serviceProviderData: [],
    dentistData: []
};

export const userManagementSlice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setServiceProviderData: (state, action) => {
            state.serviceProviderData = action.payload;
        },
        setDentistData: (state, action) => {
            state.dentistData = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setServiceProviderData,
    setDentistData
} = userManagementSlice.actions