import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  superAdminIconArray,
  spIconArray
} from "../../constants/NavbarRoutesArray";
import ServiceCarouselTile from "../../Components/UserManagement-Comps/ServiceCarouselTile";
import { DentistTab } from "../../Components/UserManagement-Comps/DentistTab";
import { ServiceProviderTab } from "../../Components/UserManagement-Comps/ServiceProviderTab";
import { useReadServiceProvidersMutation } from "../../services/user";
import { useAuth0 } from "@auth0/auth0-react";
import { setServiceProviderData } from "../../reducers/UserManagementReducer";
import { useSelector, useDispatch } from "react-redux";

function ServiceProvider({
  emailFromUserProfile,
  profileId,
  setProfileId,
  userRole,
  userInfo
}) {
  const dispatch = useDispatch();
  const { serviceProviderData } = useSelector((state) => state.userManagementDetails);
  const { getAccessTokenSilently } = useAuth0();
  const [readServiceProviders] = useReadServiceProvidersMutation();
  const [servicePrDt, setservicePrDt] = useState(serviceProviderData);
  const [isUpdated, setIsUpdated] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedCardInfo, setSelectedCardInfo] = useState('');

  console.log(selectedCardInfo, "selectedCardInfo");

  const getServiceProviderData = async (accessToken) => {
    const resp = await readServiceProviders(accessToken).unwrap();
    if (resp?.data?.length > 0) {
      // debugger;
      setservicePrDt(resp?.data);
      dispatch(setServiceProviderData(resp?.data));
    }
  };

  const readUsersInfo = async () => {
    // debugger;
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServiceProviderData(accessToken);
  };

  useEffect(() => {
    if (isUpdated === true) {
      readUsersInfo();
    }
    setIsUpdated(false);
  }, [isUpdated]);

  useEffect(() => {
    if (serviceProviderData.length === 0) {
      readUsersInfo();
    }
    setIsUpdated(false);
  }, []);

  console.log(cardDetails, "serviceProviderData");

  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  const prepareServicePrCardsData = () => {
    if (servicePrDt?.length) {
      const arrayOfData = [];
      // debugger;
      const newServiceDt = JSON.parse(JSON.stringify(servicePrDt));

      // Create HTML elements based on servicesData
      newServiceDt.reverse().map((eachCategory) =>
        arrayOfData.push(
          <ServiceCarouselTile
            value={eachCategory.count}
            header={eachCategory.groupName}
            details={eachCategory.users}
            selected={selectedCardInfo}
          />
        )
      );
      return arrayOfData;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  // array of cards for carousel here desktop
  // array of cards for carousel here mobile
  const arrayOfCardsMobile = [
    <ServiceCarouselTile header={"Basic"} value={295} />,
  ];
  // number of slider for carousel here desktop
  const numberOfSliders = [{ 0: prepareServicePrCardsData() }];
  // number of slider for carousel here mobile
  const numberOfSlidersMobile = [{ 0: prepareServicePrCardsData() }];

  const middleTabTitleArray = ["Service providers", "Dentist"];
  const middleTabContentObject = {
    Dentist: <DentistTab />,
    "Service providers": (
      <ServiceProviderTab
        numberOfSliders={prepareServicePrCardsData()}
        numberOfSlidersMobile={prepareServicePrCardsData()}
        //readUsersInfo={readUsersInfo}
        setIsUpdated={setIsUpdated}
        setCardDetails={setCardDetails}
        cardDetails={cardDetails}
        setSelectedCardInfo={setSelectedCardInfo}
      />
    ),
  };

  console.log("serPage");
  return (
    <div className="flex min-h-screen max-h-fit">
      <Navbar
        navActiveCss={navActiveCss}
        navHoverCss={navHoverCss}
        iconArray={userRole === "89uij7" ? superAdminIconArray : userRole === "676dza2" ? iconArray : spIconArray}
        middleSection={true}
        middleNavHeading="User Management"
        middleTabTitle="Service providers"
        middleNavPosition={0}
        middleTabTitleArray={middleTabTitleArray}
        middleTabContentObject={middleTabContentObject}
        userRole={userRole}
        active={'User Management'}
      />
    </div>
  );
}

export default ServiceProvider;
