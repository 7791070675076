import React, { useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
// import { AiOutlineClose } from "react-icons/ai";
import serviceProvider from "../../Assets/images/serviceProvider.png";
import dentist from "../../Assets/images/dentist.png";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import UseAccessToken from "../../hooks/UseAccessToken";
import { useUpdateRoleMutation } from "../../services/user";
import DentistLogo from "../../Assets/images/DentistLogoV2.png";
import ServiceProviderLogo from "../../Assets/images/ServiceProviderLogoV2.png";
import contentBG from "../../Assets/images/contentBG.png";
import HeaderCross from "../../Assets/images/HeaderCross.png";
import useWindowWidth from "../../Components/Accordian/WindowsWidth";
import { ConfirmToast } from "react-confirm-toast";
import { GetToast } from "../../Components/Toast-Comps/GetToast";

function Login() {
  const accessToken = UseAccessToken();
  const navigate = useNavigate();
  // use state
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userSelectedRole, setUserSelectedRole] = useState("");

  const [updateRole] = useUpdateRoleMutation();

  const handleUserSelection = (route) => {
    setUserSelectedRole(route);
    // setIsPopupOpen(true);

    // navigate(route);
  };
  const handleUserRoleUpdate = async () => {
    // first navigate the user to form
    navigate(`/${userSelectedRole}`);
    let role;

    if (userSelectedRole === "dentistForm") {
      role = "676dza2";
    } else if (userSelectedRole === "serviceProviderForm") {
      role = "34fs3";
    } else {
      role = null;
    }
    // update the role in the api
    // const accessToken = await getAccessTokenSilently({
    //   authorizationParams: {
    //     audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
    //   },
    // });
    // const payload = {
    //   r: role,
    // };

    // console.log(newAccessToken);
    // AxiosPostHook(accessToken, payload, "updateUserRole");
    const response = await updateRole({ accessToken, r: role }).unwrap();
    localStorage.setItem("crowdHireUserRole", role);
    GetToast(response["message"]);
  };

  const windowWidth = useWindowWidth();
  const HideDiv = windowWidth < 1250;

  return (
    <>
      {HideDiv ? (
        <div className="bg-[#F4F5FA] min-h-screen w-screen relative">
          <ConfirmToast
            childrenClassName="custom-confirm-toast"
            asModal={true}
            customCancel={"Back"}
            customConfirm={"Continue"}
            customFunction={() => {
              handleUserRoleUpdate();
            }}
            message={"Please be sure you have selected the right role. You will not be able to change the role associated with this email address in the future"}
            position={"top-right"}
            showCloseIcon={false}
            theme={"dark"}
          >
            <div>
              <div className="flex card-gradient justify-between items-center h-[25%]">
                <div className="md:px-40 pt-8 pb-20 px-4">
                  <p className="font-medium text-xl md:text-[34px] leading-[30px] text-[#FFFFFF]">Select Service</p>
                </div>
                <div className="md:px-52 pt-8 pb-20 px-7">
                  <img src={HeaderCross} />
                </div>
              </div>
              <div className="h-[75vh] w-full flex flex-col md:flex-row justify-center items-center gap-8 px-16 relative bg-black">
                <img className="absolute w-full h-full object-cover " src={contentBG} />
                <div onClick={() => handleUserSelection("serviceProviderForm")} className="bg-secondary border border-[#C4C6CA]  w-full md:h-[450px] md:w-[420px] flex flex-col items-center justify-center rounded-xl gap-4 md:gap-20 cursor-pointer relative z-index-10 md:mt-[-10%] card-margin">
                  <div className="p-1  w-[60%]">
                    <img src={ServiceProviderLogo} alt="serviceProvider" />
                  </div>
                  <div>
                    <p className="font-medium text-center capitalize pb-3 md:pb-0 leading-4 text-primary">Service Provider</p>
                  </div>
                  <div className="absolute h-full w-full hover:bg-blue-800 hover:bg-opacity-[0.05] rounded-lg hover:border hover:border-blue-300"></div>
                </div>

                <div onClick={() => handleUserSelection("dentistForm")} className="bg-secondary border border-[#C4C6CA] w-full md:h-[450px] md:w-[420px] flex flex-col items-center justify-center rounded-xl gap-4 md:gap-20 cursor-pointer relative z-index-10 md:mt-[-10%] card-margin">
                  <div className="p-1 md:p-0 w-[55%]">
                    <img src={DentistLogo} alt="dentist" />
                  </div>
                  <div>
                    <p className="font-medium capitalize text-center pb-3 md:pb-0 leading-4 text-primary">Dentist</p>
                  </div>
                  <div className="absolute h-full w-full hover:bg-blue-800 hover:bg-opacity-[0.05] rounded-lg hover:border hover:border-blue-300"></div>
                </div>
              </div>
            </div>
          </ConfirmToast>

          {/* <Modal
        isOpen={isPopupOpen}
        onRequestClose={() => setIsPopupOpen(false)}
        contentLabel="Popup Modal"
        className="popup-modal"
        ariaHideApp={false}
      >
        <h2>Popup Content</h2>
        <p>This is the content of the popup.</p>
        <button>Continue</button>
        <button>Back</button>
        <button className="close-button" onClick={() => setIsPopupOpen(false)}>
          Close
        </button>
      </Modal> */}
          <Modal
            isOpen={isPopupOpen}
            onRequestClose={() => setIsPopupOpen(false)}
            contentLabel="Custom Modal"
            className="popup-modal"
            // style={customStyles}
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                {/* <button
              className="w-full text-right"
              onClick={() => setIsPopupOpen(false)}
            >
              X
            </button> */}
                <p className="flex-1 text-xl text-center font-medium ">Please be sure you have selected the right role. You will not be able to change the role associated with this email address in the future</p>
                <div className="flex items-center justify-center gap-8">
                  <button className="px-4 py-2 bg-slate-400 rounded-md text-white font-medium" onClick={() => setIsPopupOpen(false)}>
                    Back
                  </button>
                  <button className="px-4 py-2 bg-orange-600 rounded-md text-white font-medium" onClick={() => handleUserRoleUpdate()}>
                    Continue
                  </button>
                </div>
              </div>
            </>
          </Modal>
        </div>
      ) : (
        <div className="bg-black min-h-screen w-screen relative">
          <ConfirmToast
            childrenClassName="custom-confirm-toast"
            asModal={true}
            customCancel={"Back"}
            customConfirm={"Continue"}
            customFunction={() => {
              handleUserRoleUpdate();
            }}
            message={"Please be sure you have selected the right role. You will not be able to change the role associated with this email address in the future"}
            position={"top-right"}
            showCloseIcon={false}
            theme={"dark"}
          >
            <>
              <div className="flex bg-black card-gradient justify-between items-center h-[30vh]">
                <div className="md:px-52 pb-16 px-4">
                  <p className="font-medium text-xl md:text-[34px] leading-[30px] text-[#FFFFFF]">Select Service</p>
                </div>
                <div className="md:px-52 pt-8 pb-20 px-7 ">
                  <img src={HeaderCross} />
                </div>
              </div>
              <div className="h-[70vh] bg-secondary w-full flex flex-col md:flex-row justify-center items-center gap-8 px-16 relative">
                {/* <img className="absolute w-full h-full object-cover" src={contentBG} /> */}
                <div className="flex gap-5 w-full flex-col md:flex-row  p-5 md:justify-center items-center justify-center md:items-start mt-[-10%]">
                  <div onClick={() => handleUserSelection("serviceProviderForm")} className="bg-secondary border border-[#C4C6CA] aspect-[15/16] w-full md:w-[38%] flex flex-col items-center justify-around rounded-xl gap-4 md:gap-20 cursor-pointer relative z-index-10">
                    <img src={ServiceProviderLogo} alt="serviceProvider" className="h-[85%]" />

                    <p className="font-medium text-center uppercase pb-3 leading-4 text-white text-2xl">Service Provider</p>
                    <div className="absolute h-full w-full hover:bg-blue-800 hover:bg-opacity-[0.05] rounded-lg hover:border hover:border-blue-300"></div>
                  </div>
                  <div onClick={() => handleUserSelection("dentistForm")} className="bg-secondary border border-[#C4C6CA]  aspect-[15/16] w-full md:w-[38%]  flex flex-col items-center justify-around rounded-xl gap-4 md:gap-20 cursor-pointer relative z-index-10">
                    <img src={DentistLogo} alt="dentist" className="h-[85%]" />

                    <p className="font-medium uppercase text-center pb-3 leading-4 text-white text-2xl">Dentist</p>
                    <div className="absolute h-full w-full hover:bg-blue-800 hover:bg-opacity-[0.05] rounded-lg hover:border hover:border-blue-300"></div>
                  </div>
                </div>
              </div>
            </>
          </ConfirmToast>
          {/* <Modal
        isOpen={isPopupOpen}
        onRequestClose={() => setIsPopupOpen(false)}
        contentLabel="Popup Modal"
        className="popup-modal"
        ariaHideApp={false}
      >
        <h2>Popup Content</h2>
        <p>This is the content of the popup.</p>
        <button>Continue</button>
        <button>Back</button>
        <button className="close-button" onClick={() => setIsPopupOpen(false)}>
          Close
        </button>
      </Modal> */}
          <Modal
            isOpen={isPopupOpen}
            onRequestClose={() => setIsPopupOpen(false)}
            contentLabel="Custom Modal"
            className="popup-modal"
            // style={customStyles}
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                {/* <button
              className="w-full text-right"
              onClick={() => setIsPopupOpen(false)}
            >
              X
            </button> */}
                <p className="flex-1 text-xl text-center font-medium ">Do you want to continue to enroll for this rolls, If you want to change your role you can write to admin@crowdhire.com</p>
                <div className="flex items-center justify-center gap-8">
                  <button className="px-4 py-2 bg-slate-400 rounded-md text-white font-medium" onClick={() => setIsPopupOpen(false)}>
                    Back
                  </button>
                  <button className="px-4 py-2 bg-orange-600 rounded-md text-white font-medium" onClick={() => handleUserRoleUpdate()}>
                    Continue
                  </button>
                </div>
              </div>
            </>
          </Modal>
        </div>
      )}
    </>
  );
}

export default withAuthenticationRequired(Login);
// export default Login;

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button className="relative px-4 py-2 flex items-center rounded-xl bg-white font-medium text-[15px] text-black border border-[#dfdfdf]" onClick={() => loginWithRedirect()}>
      Log In
    </button>
  );
};

export const LogoutButton = () => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    localStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return <button onClick={() => handleLogout()}>Log Out</button>;
};

export const LogoutFunction = () => {
  const { logout } = useAuth0();
  localStorage.clear();
  logout({ logoutParams: { returnTo: window.location.origin } });
};
